import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quality-policy',
  templateUrl: './quality-policy.component.html',
  styleUrls: ['./quality-policy.component.css']
})
export class QualityPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('top-hide').style.display="none";
  }

}
