import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { HomeComponent } from '../layout/home/home.component';
import { IndustryComponent } from '../layout/industry/industry.component';
import { MethodologyComponent } from '../layout/methodology/methodology.component';
import { ProfileComponent } from '../layout/profile/profile.component';
import { WhyusComponent } from '../layout/whyus/whyus.component';
import { QualityPolicyComponent } from '../layout/quality-policy/quality-policy.component';
import { ContactUsComponent } from '../layout/contact-us/contact-us.component';
import { VisaServiceComponent } from '../layout/visa-service/visa-service.component';
import { RecruitmentComponent } from '../layout/recruitment/recruitment.component';
import { EmigrationClearanceComponent } from '../layout/emigration-clearance/emigration-clearance.component';
import { DocumentAttestationComponent } from '../layout/document-attestation/document-attestation.component';
import { FlightReservationComponent } from '../layout/flight-reservation/flight-reservation.component';
import { LoginDialogComponent } from '../layout/login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from '../layout/registration-dialog/registration-dialog.component';
import { JobsComponent } from '../layout/jobs/jobs.component';
import { CandidatesEnquiryComponent } from '../layout/candidates-enquiry/candidates-enquiry.component';
import { EmployersEnquiryComponent } from '../layout/employers-enquiry/employers-enquiry.component';
import { PostResumeComponent } from '../layout/post-resume/post-resume.component';
import { TrackStatusComponent } from '../layout/track-status/track-status.component';


export const homeRoutes: Routes = [
    {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '',   redirectTo: 'home', pathMatch: 'full' },
      { path: 'home',  component: HomeComponent },
	  { path: 'industry',  component: IndustryComponent },
	  { path: 'methodology',  component: MethodologyComponent },
	  { path: 'profile',  component: ProfileComponent },
	  { path: 'whyus',  component: WhyusComponent },
	  { path: 'quality-policy',  component: QualityPolicyComponent },
    { path: 'contact-us',  component: ContactUsComponent },
    { path: 'visa-service',  component: VisaServiceComponent },
    { path: 'recruitment',  component: RecruitmentComponent },
    { path: 'emigration-clearance',  component: EmigrationClearanceComponent },
    { path: 'document-attestation',  component: DocumentAttestationComponent},    
    { path: 'document-attestation', loadChildren: () => import('../layout/document-attestation/attestation.module').then(m => m.AttestationModule) },
    { path: 'flight-reservation',  component: FlightReservationComponent },
    { path: 'login-dialog',  component: LoginDialogComponent },    
    { path: 'registration-dialog',  component: RegistrationDialogComponent },
    { path: 'jobs',  component: JobsComponent },
    { path: 'candidates-enquiry',  component: CandidatesEnquiryComponent },
    { path: 'employers-enquiry',  component: EmployersEnquiryComponent },
    { path: 'post-resume',  component: PostResumeComponent },
    { path: 'track-status',  component: TrackStatusComponent },

    ]
    
  },
];

export class LayoutRoutingModule { }
