import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candidates-enquiry',
  templateUrl: './candidates-enquiry.component.html',
  styleUrls: ['./candidates-enquiry.component.css']
})
export class CandidatesEnquiryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
