<div class="home-page" (scroll)="onWindowScroll($event);">
<div class="top-slider">
	  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <img src="{{slide.img}}" alt="" width="100%">
        <div class="banner_content text-center">
          <p class="wow slideInLeft">{{slide.bannercaption}}</p>
        </div>
      </div>
	  </ngx-slick-carousel>

	 </div>  
	  <div class="slider-below-section">
            <div class="container">
              <div class="iso-certified-row">
                <div class="left-side-section">                 
                  <h2>Human Resources Consultants
                    <!-- <br><span>Approved By</span> -->
                  </h2>
                  <!-- <p>Approvd by Ministry of External Afair</p>
                  <p>License No:- B-0205/DEL/PER/1000+/5/8014/2007</p> -->
                  <!-- <figure>
                    <img src="assets/img/ministry-min2.png">
					<p>License No:- B-0205/DEL/PER/1000+/5/8014/2007</p>
                  </figure>    -->
					
                </div>
                <div class="right-side-section">                
                  <p>ISO 9001:2015 Certified</p>
                  <img class="iso" src="assets/img/iso.jpg">   
					
                </div>
              </div>
            </div>
          </div>
 <!--about start --> 
      <div class="about-section">
        <div class="container">
          <div class="about-section-row d-flex justify-content-between align-items-center flex-wrap">
           
              <div class="welcome-area wow slideInUp">
                <div class="welcome-area-content">
                <h2 class="mb-3">Welcome to Rollic India</h2>
                <p class="mb-5">Rollic India is a professionally managed consulting and training firm approved by Ministry of 
                  Overseas Indian Affairs, Government of India License No:- <strong>B-0205/DEL/PER/1000+/5/8014/2007, 
                    valid upto 02 May 2022</strong>  based in New Delhi, India. The company was founded in 2005 and has experienced 
                  fabulous growth since then. Rollic India offers a variety of "Outsourced" services either on a short-term, 
                  long-term, interim , or project consulting basis , 
                  provided either on or off the client's site..</p>
                 <!--  <a class="readmore" href="#">Read more ..</a> -->
                </div>
                  <div class="counter-area d-flex justify-content-around align-item-center text-left pt-4">
                    <div>
                      <h1 class="count">512</h1>
                      <p>Satisfied Clients</p>
                    </div>
                    <div>
                     <h1 class="count">910</h1>
                      <p>Project Done</p>
                    </div>
                    <div>
                     <h1 class="count">313</h1>
                     <p>Expert Members</p>
                    </div>
                 </div>
                 <div class="aboutus-btn">
                   <a routerLink="/profile" routerLinkActive="active">About Us</a>
                 </div>

              </div>
            
      
              <!-- <figure class="about-img d-flex justify-content-center align-item-center">
                <img src="images/about.jpg">
              </figure> -->
              <div class="message-box slideInRigh wow bounceInUp ease">
                <h2 class="">Message of Proprietor</h2>
                <figure>
                  <img src="assets/img/profile-pic.png">
                  <figcaption class="font-weight-bold">Mr. Musharaf Ali</figcaption>
                </figure>
                <div class="message-content">
                  <p>Thank you for taking time to visit our website. Our website is one simple communication gateway that will give you a better understanding of our services.</p>
                  <p>Rollic India was established for providing quality service & opportunities to our candidates desirous of working in Middle East and likewise our valuable clients who wish to hire the best manpower resources from India.</p>
                  <!-- <p>Our services target to maintain long term partnership with our valuable clients and have some of them for over 25 years.</p> -->
                 <!--  <p>We would certainly be a valuable partner to our clients to help minimize their Human Resource risks & optimize their Profit & Growth with highest quality services in an ethical & professional manner.</p> -->
                  <p>Once again I would like to thank you for your interest & look forward to your accompanying us as we continue on this extremely exciting journey.</p>
                </div>
                
              </div>
            
          </div>
        </div>
      </div>
	  <!--About section end-->	  
	        <!--services section start-->
      <div class="services-section" id="services">
        <div class="container">
          
             <div class="section-heading">
                <span>WHY CHOOSE US</span>
                <h2>Our Services </h2>
              </div>
           
            <div class="row mb-4">
              <div class="col-lg-8 col-md-12 col-12">
                <div class="rollic-service wow bounceInUp ease">
                <div class="service-top">                   
                      Our additional features provide support to our customer and makes project successful.
                </div>
                <div class="service-box-area">
                <div class="col-lg-6 col-md-6 col-12">
                  <a routerLink="/visa-service" routerLinkActive="active">
                    <div class="service-boxes">
                      <div class="icon-area">
                        <span><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><rect fill="none" height="24" width="24"/><path d="M19,5h-2V3H7v2H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94c0.63,1.5,1.98,2.63,3.61,2.96V19H7v2h10v-2h-4v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z"/></svg></span>
                      </div>
                      <div class="box-contents">
                          <h4>GCC visa Facilitation</h4>
                        <p>An Environmental health & safety consultant can work to ensure each step construction process.</p>
                      </div>
                  </div>
                </a> 
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <a routerLink="/recruitment" routerLinkActive="active">
                     <div class="service-boxes">
                      <div class="icon-area">
                        <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M0 0h24v24H0z" fill="none"/><path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/></svg></span>
                      </div>
                      <div class="box-contents">
                      <h4>Recruitment</h4>
                      <p>An Environmental health & safety consultant can work to ensure each step construction process.</p>
                    </div>
                    </div>
                  </a>
                </div>
          
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="service-boxes">
                    <div class="icon-area">
                      <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M24 24H0V0h24v24z" fill="none"/><path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"/></svg></span>
                    </div>
                    <div class="box-contents">
                    <h4>Visa Facilitation Services</h4>
                    <p>Creating a realistic plan is a key responsibility of our experts and we always delivered Ontime </p>
                  </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <a routerLink="/document-attestation/hrd-attestation">
                    <div class="service-boxes">
                      <div class="icon-area">
                        <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-2.5c2.33 0 4.32-1.45 5.12-3.5h-1.67c-.69 1.19-1.97 2-3.45 2s-2.75-.81-3.45-2H6.88c.8 2.05 2.79 3.5 5.12 3.5z"/></svg></span>
                      </div>
                      <div class="box-contents">
                      <h4>HRD Document Attestation</h4>
                      <p>An Environmental health and safety consultant can work to ensure each step.</p>
                    </div>
                    </div>
                  </a>  
                </div>
              
                <div class="col-lg-6 col-md-6 col-12">
                  <a routerLink="/document-attestation/mea-attestation">
                     <div class="service-boxes">
                      <div class="icon-area">
                        <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"/></svg></span>
                      </div>
                      <div class="box-contents">
                      <h4>MEA Attestation</h4>
                      <p>Award winners and know the value of winning an award and they know what it takes.</p>
                    </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <a routerLink="/document-attestation/mea-apostille">
                    <div class="service-boxes">
                      <div class="icon-area">
                        <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M0 0h24v24H0z" fill="none"/><path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/></svg></span>
                      </div>
                      <div class="box-contents">
                      <h4>MEA Apostille</h4>
                      <p>Creating a realistic plan is a key responsibility of our experts and we always delivered Ontime </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <a routerLink="/document-attestation/embassy-attestation"> 
                    <div class="service-boxes">
                      <div class="icon-area">
                        <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M0 0h24v24H0V0z" fill="none"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><circle cx="15.5" cy="9.5" r="1.5"/><circle cx="8.5" cy="9.5" r="1.5"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-2.5c2.33 0 4.32-1.45 5.12-3.5h-1.67c-.69 1.19-1.97 2-3.45 2s-2.75-.81-3.45-2H6.88c.8 2.05 2.79 3.5 5.12 3.5z"/></svg></span>
                      </div>
                      <div class="box-contents">
                        <h4>Embassy Attestation</h4>
                        <p>Creating a realistic plan is a key responsibility of our experts and we always delivered Ontime </p>
                      </div>
                    </div>
                    </a>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="service-boxes">
                    <div class="icon-area">
                      <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="48px" height="48px"><path d="M24 24H0V0h24v24z" fill="none"/><path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z"/></svg></span>
                    </div>
                    <div class="box-contents">
                    <h4>Flight Reservations</h4>
                    <p>An Environmental health and safety consultant can work to ensure each step.</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="form-box wow bounceInUp ease">
                  <div class="box">
                    <h2>Get Free Experts Help</h2>
                    <div class="inputBox">
                      <input class="inputText" type="text" name="name" required autocomplete="off">
                      <label>Enter your name</label>
                    </div>
                    <div class="inputBox">    
                      <input class="inputText" type="text" name="email" required autocomplete="off">
                      <label>Enter your email address</label>
                    </div>
                    <div class="inputBox">
                      <input class="inputText" type="text" name="mobile" required autocomplete="off">
                      <label>Enter your contact number</label>
                    </div>
                    <div class="select-service">
                      <select id="service_select" class="form-control" name="services" required="">
                        <option value="">- Services -</option>
                        <option value="Visa stamping">Visa stamping </option>
                        <option value="Certificate attestation">Certificate attestation</option>
                        <option value="Embassy attestation">Embassy attestation</option>
                        <option value="Hrd attestation">Hrd attestation</option>
                        <option value="Home department attestation">Home department attestation</option>
                        <option value="Norka attestation">MEA Attestation</option>
                        <option value="Norka attestation"> MEA Apostille</option>
                        <option value="Other">Other </option>
                      </select>
                      <!-- <input class="inputText" type="text" name="mobile" required autocomplete="off">
                      <label>Enter your contact number</label> -->
                    </div>
                    <div class="submit-btn">
                      <input type="submit" value="Submit Request">
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
      <!--services section end-->
	    <!-- industry section start -->
	 <div class="industry-section">
        <div class="container">
          <!-- <div class="col-lg-12 col-md-12 col-12"> -->
            <div class="industry-head">
              <h2>Industry We Serve</h2>
            </div>
          <!-- </div> -->
		  <!-- <div class="col-lg-12 col-md-12 col-12"> -->
			<div class="industry-slider">
				<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="IndustryslideConfig">
       
            <div ngxSlickItem *ngFor="let slide of Industryslides" class="slide">
              <div class="image-holder">
                <img class="slider-image" src="{{slide.img}}" alt="" width="100%">
                <div class="industry-box-contents">
                  <h4 class="box-heading">{{slide.heading}}</h4>
                  <p class="box-para">{{slide.paragraph}}</p>
                </div>
                
              </div>
            </div>
         
			  </ngx-slick-carousel>
      </div>
      <div class="aboutus-btn view-cat text-center">
        <a routerLink="/industry" routerLinkActive="active">View All</a>
      </div>
		  <!-- </div> -->
		</div>
	</div>
	 <!-- industry section end -->	  
	 <!-- posting-resume section start -->
       <div class="posting-resume-section">         
           <div class="posting-contents">
             <h2><span>SUBMIT</span> YOUR RESUME NOW!</h2>
             <h4>Your career gateway to find a best job as per your <span>requirement.</span></h4><br>
             <a class="post-cv" href="#" routerLink="/post-resume" routerLinkActive="active">POST YOUR RESUME</a>
           </div>         
       </div>
       <!-- posting-resume section end -->
<!-- feedback section start -->    
<div class="feedback-section">
<div class="container">
      <div class="feedback-heading">
        <span>CLIENTS GOLDEN WORDS</span>
        <h2>Reviews & Feedback </h2>
      </div>
	<div class="testimonial">      
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="testimonialConfig">
        <div ngxSlickItem class="slide">
          <div class="item carousel-item active">
            <div class="img-box"><img src="../../../assets/img/profile-pic.png" alt=""></div>
            <p class="testimonial-para">Phasellus vitae suscipit justo. Mauris pharetra feugiat ante id lacinia. Etiam faucibus mauris id tempor egestas. Duis luctus turpis at accumsan tincidunt. Phasellus risus risus, volutpat vel tellus ac, tincidunt fringilla massa. Etiam hendrerit dolor eget rutrum.</p>
            <p class="overview"><b>Michael Holz</b>Seo Analyst at <a href="#">OsCorp Tech.</a></p>
          </div>
        </div>	
        <div ngxSlickItem class="slide">
          <div class="item carousel-item active">
            <div class="img-box"><img src="../../../assets/img/profile-pic.png" alt=""></div>
            <p class="testimonial-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu sem tempor, varius quam at, luctus dui. Mauris magna metus, dapibus nec turpis vel, semper malesuada ante. Vestibulum idac nisl bibendum scelerisque non non purus. Suspendisse varius nibh non aliquet.</p>
            <p class="overview"><b>Paula Wilson</b>Media Analyst at <a href="#">SkyNet Inc.</a></p>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="item carousel-item active">
            <div class="img-box"><img src="../../../assets/img/profile-pic.png" alt=""></div>
            <p class="testimonial-para">Vestibulum quis quam ut magna consequat faucibus. Pellentesque eget nisi a mi suscipit tincidunt. Utmtc tempus dictum risus. Pellentesque viverra sagittis quam at mattis. Suspendisse potenti. Aliquam sit amet gravida nibh, facilisis gravida odio. Phasellus auctor velit.</p>
            <p class="overview"><b>Antonio Moreno</b>Web Developer at <a href="#">Circle Ltd.</a></p>
          </div>
        </div>
      </ngx-slick-carousel>
      </div>      
    </div>
  </div>
   <!-- feedback section end -->  
       <!-- client section start -->  
    <div class="clients-section">
		<div class="container">       
			<div class="client-head">
			  <h2>Our Clients</h2>
			  <p>We work with our clients and partners to <span>delivered flawless & ontime</span> projects</p>
			</div>
			<div class="thumbnail-slider">
				<ngx-slick-carousel class="carousel thumbnail-carousel" #slickModal="slick-carousel" [config]="thumnailslideConfig">
					<div ngxSlickItem *ngFor="let slide of thumnailslides" class="slide thumbnail-slide">
						<div class="image-holder client-logo">
						  <img class="slider-image" src="{{slide.img}}" alt="" width="100%">
						  
					  </div>
					</div>
			  </ngx-slick-carousel>
			</div>
		</div>
	</div>	
	
<div class="footer-top">
     <div class="container">
     <div class="footer-top-content d-flex justify-content-between align-items-center">   
         <h3>For Free & Detailed solution: Contact our Experts 24/7</h3>  
         <div class="contact-btn">
           <a class="contact-now" href="#">Contact Now</a>
        </div>         
     </div>
    </div>
  </div>

</div>