<div class="profile-page">
	   <div class="page-banner" id="header-eliment">
        <div class="container">
            <div class="banner-contents">             
              <h2>Company Profile</h2>
              <div class="d-flex align-items-center">
                <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Company Profile</p>
              </div>
            </div> 
          </div>        
      </div>
    <div class="profile-area">
        <div class="container">
         <h4 class="my-4 profile-head"> About Rollic india</h4>
         <p><img class="section-img" src="../../../assets/img/About-us.png"/>
		 Rollic India Agency, established in 2005  is one of the leading Overseas workforce solution provider in India approved by the Ministry of External Affairs, Government of India, License No: <strong>B-0205/DEL/PER/1000+/5/8014/2007. </strong>We are 9001: 2015 ISO certified. </p>
         <p>Rollic India, is an International Recruitment Consultants agency, approved by Ministry of Overseas Indian Affairs, Govt. of India and provides end-to-end manpower outsourcing services for International Placement. The company has expertise in manpower recruitment solutions, job placements , employment assistance, e-staffing solutions. We have accomplished over hundreds of recruitment assignments successfully in the last 13 years for international placements especially for Gulf region.</p>
         <p>We have been providing manpower consulting services for last 13 years. Rollic India offers employees and employers a range of services for the entire employment and business cycle including permanent, temporary and contract recruitment; employee assessment and selection; training; outplacement; outsourcing and consulting. we are generally  providing manpower consultancy for Health care industry( Doctors, nurses and paramedical staff), Hospitality, Security  staff services, Construction, Oil & Refinery, Maintenance, , Information Technology, Education or you may be hunting for dexterous artists or may be Masons, Carpenters, RCC, Gardeners, Cleaners, Drivers, Machine Operators and all kinds of Mechanics & Technicians etc.   </p>
         <p>We involve employees at all levels and bring focus to how an employee's daily job functions and core competencies directly impacts and links to the overall success of the organization as per the following:</p>
         <ul class="mb-3 list-rules">
           <li> 
            All type of embassy related work like VISA stamping, attestation etc.</li>
           <li>Global corporations planning diversification and expansion as part of their existing enterprise.</li>
           <li>International companies planning for setting up liaison offices, joint ventures and trading offices.</li>
           <li>Indian companies or groups process of restructuring current operations, planning expansion, diversification.</li>
           <li> Family businesses aiming to professionalize their management.</li>
           <li>Entrepreneurial ventures that require HR systems and processes.</li>
           <li> Any enterprise wanting to introduce personnel systems of management for enhanced recruitment, motivation, retention and management of human resources.</li>
           <li>professionals wanting to assess skills and capabilities for future prospects. </li>
           <li> Top Management Professionals mapping career growth paths within their existing employment by identifying competencies and areas of improvement. </li>
           <li> Junior to Middle and Middle to senior managers seeking guidance on current profession, seeking suitable employment options and exploring alternative employment.  </li>
         </ul>
         <p>Finally, we sincerely feel that we can certainly add value to your organization in identifying and placing the right resources. I would appreciate hearing your comments, and will look forward to answering any questions you have about our services.</p>

        </div>


       

        <!-- <div class="contactus-bg-section">
          <div class="contaner">
              <div class="bg-contents-area text-center">
                 <h1>Need a Consultant?</h1>
                 <p>Looking for a Business Plan Consultant. Contact some of our Expert or our Agent, We are hear to help you out.</p>
                 <a href="" routerLink="/contact-us" routerLinkActive="active"><button type="button" class="green-btn"> Contact Us</button></a>
              </div>
          </div>
      </div> -->
    </div>
    <div class="quoet-section mt-5 d-flex flex-wrap">
        
      <div class="col-md-6 quoet-blog">
               <div class="col-md-8 offset-md-2 quoet-form">
                   <h1 class="section-heading">Request A Quote </h1> 
                   <form>
                       <div class="row">
                           <div class="col-md-12 form-group">
                               <input type="text" class="form-control" placeholder="Name">
                           </div>
                       </div>
                       <div class="row">
                           <div class="col-md-12 form-group">
                               <input type="text" class="form-control" placeholder="Phone Number">
                           </div>
                       </div>
                       <!-- <div class="row">
                           <div class="col-md-12 form-group">
                               <input type="text" class="form-control" placeholder="Email Id">
                           </div>
                       </div> -->
                       <div class="row">
                        <div class="col-md-6 form-group">
                          <input type="text" class="form-control" placeholder="Email Id">
                        </div>
                           <div class="col-md-6 form-group">
                               <select id="inputState" class="form-control">
                                  <option value="">- Services -</option>
                                  <option value="Visa stamping">Visa stamping </option>
                                  <option value="Certificate attestation">Certificate attestation</option>
                                  <option value="Embassy attestation">Embassy attestation</option>
                                  <option value="Hrd attestation">Hrd attestation</option>
                                  <option value="Home department attestation">Home department attestation</option>
                                  <option value="Norka attestation">MEA Attestation</option>
                                  <option value="Norka attestation"> MEA Apostille</option>
                                  <option value="Other">Other </option>
                                </select>
                           </div>
                       </div>
                       <div class="row">
                           <div class="col-md-12 form-group">
                               <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Type Your Message Here.."></textarea>
                           </div>
                       </div>
                       <div class="aboutus-btn">
                        <a href="#" >SUBMIT</a>
                      </div>
                     </form>
               </div>
      </div>
      <div class="col-md-6 contact-blog">
           <div class="col-md-8 offset-md-2">
               <div class="contact-box">
                   <h1 class="section-heading">Contact Us</h1> 
                   <div class="contact-box-row d-flex mb-3">
                       <i class="fas fa-map-marker-alt"></i>
                       <p>A-4, First Floor, Taimoor Nagar, New Delhi-110065</p>
                   </div>
                   <div class="contact-box-row d-flex align-items-center mb-3">
                       <i class="fas fa-phone-alt"></i>
                       <p>Contact Us At: <a href="tel:+91 9313622422">+91 9313622422</a></p>
                   </div>
                   <div class="contact-box-row d-flex align-items-center mb-3">
                       <i class="far fa-envelope"></i>
                       <p>Email: info@rollic.com</p>
                   </div>
                   <div class="contact-box-row d-flex align-items-center mb-3">
                       <i class="fas fa-globe-americas"></i>
                       <p>Website: www.rollic.com</p>
                   </div>
               </div>
           </div>
      </div>
  </div>
</div>