<div class="methodolgy-page">   
   <div class="page-banner" id="header-eliment">
      <div class="container">
           <div class="banner-contents">             
             <h2>QUALITY AND VALUE SYSTEM</h2>
             <div class="d-flex align-items-center">
              <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Quality and Value System</p>
           </div>
           </div> 
      </div>          
    </div>
    <div class="profile-area">
        <div class="container">
         <h4 class="my-4 profile-head"> Quality and Value System</h4>
         <p>
		 <img class="section-img" src="../../../assets/img/quality.jpg"/>
		 In a highly competitive market, Rollic India continually provides quality manpower that surpasses the expectations of its valued Clients . There is no compromise on the 
		 candidate’s eligibility for the position in question. We believe in building a team of quality people who can prove their worth and turn out to be real assets to 
		 Clients , ultimately benefitting everyone concerned - Clients , candidates and even us. We understand how vital quality assurance is in the service industry, and 
		 we are aware that our reputation and business depend on adherence to these self-formulated quality policies. Rollic India does not believe in one-time assignments or 
		 associations - Repeat orders from valuable Clients is what Rollic India strives to ensure. Strictly adhering to its quality policies and norms, Rollic India takes pride in the fact 
		 that most Clients have been continuously associated with Rollic India for over two decades.</p>
        
         <h5 class="my-3"><strong>VALUE SYSTEMS</strong></h5>
		<p>We at Rollic India place high emphasis on a strong Value System, and work hard to create such a Value System in the overseas recruitment field through our work.</p>
		
		<p><strong>Rigorous training: </strong> We provide short-term vocational training in various fields as per the potential and specific interests of each individual candidate. Our candidates are technically trained to be skilled Shuttering Carpenters, Painters, Welders, Masons, Riggers, Automobile Technicians, Tailors, Fabricators, Duct Fitters, Insulators, etc.</p>
		<p><strong>Social responsibility: </strong> We believe that generating new employment opportunities is one of best solutions towards eradicating poverty in India. Rollic India is highly conscious about its social responsibilities and is striving to eradicate the country’s unemployment by providing plenty of overseas job opportunities to India’s youth. This will also help our Clients get the best and extremely affordable manpower.</p>
		<p>We identify hidden talents of the youth, even in the most backward and undeveloped/remote Indian villages. Creating overseas job opportunities and providing a stable career to the less fortunate village youth in important fields like construction and other industries helps these people become self-confident and self-dependent. Individuals coming from socially neglected areas thereby get economic growth opportunities they could never otherwise dream of Well-paid overseas jobs to the less fortunate youth ensure basic education to their children, better housing, and financial stability for their future. This has been made possible by Rollic India all over India, especially in several villages in Kerala, Tamil Nadu and Karnataka that have witnessed amazing economic development in recent years and Rollic India could make it happen because of the continued support and patronage of its overseas Clients , who in turn get the best, most cost-efficient and skilled talent, combined with loyalty, hard work, dedication and sincerity. These qualities of the recruited Indian personnel ultimately prove to be the employers’ biggest strength.</p>
		
         <h5 class="my-3"><strong>TRUST, RECIPROCITY, AND LIVING UP TO PROMISES</strong></h5>
         <p>We build high levels of trust and reciprocity with our Clients , helping them feel comfortable, confident, and safe in putting their faith and trust in us. Our industry in general and work in particular is defined by how much value we add to others’ businesses. This is where our extensive experience in the HR industry - our prized asset and greatest legacy – comes to the fore. Additionally, complete clarity and transparency in our services help further boost the business of our international Clients .</p>
		<p><strong>At affordable costs, we</strong></p>
          <ul class="mb-3 list-rules">
            <li>Offer in-depth knowledge, relevant advice, beneficial ideas and excellent recruitment strategies to help our Clients succeed and execute their projects on or before time</li>
            <li>Facilitate faster communication by responding to each e-mail and call in real time</li>
            <li>Fulfil our promises, stand by our guarantees and deliver consistency and quality along with highest value to our Clients</li>
          </ul>
          <p>Our uniqueness in this industry is based on the fact that we know the nitty-gritty, responsibility and sensitivity of each project, its category and the associated impact. Thanks to this, our valued Clients never deviated from our services, right from the start.</p>
          <h5 class="my-3"><strong>RELATIONSHIPS THAT GO BEYOND RECRUITMENT</strong></h5>
         <p>We have consistently maintained extremely healthy and cordial relationships with all our Clients , thanks to our close involvement with them and our sincere, dedicated focus on client delight. Our Clients consider us more than just their business associates and recruiting agents - We comprise their entire HR department, and therefore all our recruitment ideas, suggestions and advice are given due recognition, value and weightage. In addition, our speedy, prompt and streamlined services and support make our Clients trust us and stick with us.</p>
		 <p>Our services and relationship with Clients and candidates extend beyond talent supply, candidate recruitment and deployment. We outline clear expectations, define challenges, and establish accountability systems and follow up programs with our Clients – all of which help us know and understand better about the progress and development that takes place after recruitments are complete.</p>
        </div>
    </div>
</div>