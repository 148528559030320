<section class="header">   
	<div class="header-top" id="top-hide">
		<div class="container">
			<div class="top-section d-flex justify-content-between align-items-center flex-wrap">
				<div class="contact-detail d-flex">
					<!-- +91 95912 06261 | info@poweroffactorial.com -->
					<i class="fas fa-map-marker-alt"></i>
					<!-- <i class="fa fa-map-marker" aria-hidden="true"></i> -->
                    <p class="address">A-4 First Floor, Taimoor Nagar, New Delhi-110065</p>
				</div>
				<div class="news">
					<!-- <p>License No:- B-0205/DEL/PER/1000+/5/8014/2007</p> -->
					<marquee direction="left" scrolldelay="1">Approved by Government of india (License No:- B-0205/DEL/PER/1000+/5/8014/2007).</marquee>
				</div>
				<!-- <div class="top-nav-area d-flex align-items-center justify-content-between"> -->
					<!-- <div class="top-nav">
						<ul class="d-flex">
							<li><a href="aboutUs.html">About Us</a></li>
							<li><a href="#">Knowlegde</a></li>
							<li><a href="contactus.html">Contact Us</a></li>
						</ul>
					</div> -->
					<div class="login-area d-flex">
						<img src="assets/img/clock.svg" alt=""> <p>Monday – Saturday 10 AM – 6 PM</p>
						<!-- <a href="#">Login</a> / <a href="#">Register</a> -->
					</div>
				<!-- </div> -->
			</div>
		</div>        
	</div>	       
       <div class="header-static" id="tophead">
            <div class="container">
           
            <div class="main-header navbar navbar-expand-lg navbar-light">
				<div class="logo">
                	<a routerLink="/home" routerLinkActive="active"><img src="assets/img/logo.png"></a>
				</div>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				  </button>
				<div class="collapse navbar-collapse" id="navbarNavDropdown">
					<ul class="nav-bar navbar-nav">
                          <li class="efect"><a href="#" routerLink="/home" routerLinkActive="active">Home</a></li>
                          <li class="efect"><span class="drop-icon">Company</span>
							<ul class="inner-dropdown">
								<li><a class="dropdown-list" routerLink="/profile" routerLinkActive="active">Profile</a></li>
								<li><a class="dropdown-list" routerLink="/whyus" routerLinkActive="active">Why Us</a></li>
								<li><a class="dropdown-list" routerLink="/methodology" routerLinkActive="active">Methodology</a></li>
								<!-- <li><a class="dropdown-list" href="#">Clients</a></li> -->
								<li><a class="dropdown-list" routerLink="/industry" routerLinkActive="active">Industry We serve</a></li>
								<li><a class="dropdown-list" routerLink="/quality-policy" routerLinkActive="active">Quality Policy</a></li>
							</ul>
						  </li>
                          <!-- <li class="efect"><span class="drop-icon">Workflow</span>
							<ul class="inner-dropdown">
								<li><a class="dropdown-list" href="#">Assessment & Selection</a></li>
								<li><a class="dropdown-list" href="#">Training & Development</a></li>
								<li><a class="dropdown-list" href="#">Consulting Services</a></li>
								<li><a class="dropdown-list" href="#">Outsoursing</a></li>
								<li><a class="dropdown-list" href="#">Outplacement</a></li>
								<li><a class="dropdown-list" href="#">Jobs</a></li>
								<li><a class="dropdown-list" href="#">Recruitment</a></li>
							</ul>
						  </li> -->
                          <li class="efect"><span class="drop-icon" >Our Services</span>
							<ul class="inner-dropdown">
								<li><a class="dropdown-list" href="#" routerLink="/recruitment" routerLinkActive="active">Recruitment</a></li>
								<li><a class="dropdown-list" href="#" routerLink="/visa-service" routerLinkActive="active">Visa Facilitation Services</a></li>
								<li><a class="dropdown-list" href="#" routerLink="/emigration-clearance" routerLinkActive="active">Emigration Clearance</a></li>
								<li class="inner-dropdown-li"><a class="dropdown-list" href="#" routerLink="/document-attestation" routerLinkActive="active"><span class="drop-icon icon-rotate">Document Attestation</span></a>
									<!-- <a class="dropdown-list" href="#" routerLink="/document-attestation" routerLinkActive="active">Document Attestation</a> -->
										<ul class="inner-dropdown-inner">
											<li><a class="dropdown-list" href="#" routerLink="/document-attestation/embassy-attestation">Embassy Attestation</a></li>
											<li><a class="dropdown-list" href="#" routerLink="/document-attestation/hrd-attestation">HRD Document Attestation</a></li>
											<li><a class="dropdown-list" routerLink="/document-attestation/mea-attestation">MEA Attestation</a></li>
											<li><a class="dropdown-list" href="#" routerLink="/document-attestation/mea-apostille">MEA Apostille</a></li>
											<li><a class="dropdown-list" href="#" routerLink="/document-attestation/home-department">Home Department</a></li>
										</ul>	
								
								</li>
								
								<li><a class="dropdown-list" href="#">Flight Reservations</a></li>
							</ul>
						  </li>
						    <li class="efect"><span class="drop-icon">Jobseekers</span>
							<ul class="inner-dropdown">
								<li><a class="dropdown-list" (click)="loginDialog()">Login</a></li>
								<li><a class="dropdown-list"  (click)="registerDialog()">Registrion</a></li>
								<li><a class="dropdown-list" href="#" routerLink="/track-status" routerLinkActive="active">Track your Application Status</a></li>
								<li><a class="dropdown-list" href="#" routerLink="/jobs" routerLinkActive="active">Jobs</a></li>
							</ul>
						  </li>
						  <!-- <li class="efect"><span class="drop-icon">Enquiry</span>
							<ul class="inner-dropdown">
								<li><a class="dropdown-list" href="#">Condidates Enquiry</a></li>
								<li><a class="dropdown-list" href="#" routerLink="/employers-enquiry" routerLinkActive="active">Foreign Employer</a></li>								
							</ul>
						  </li> -->
						  <li class="efect"><a href="#" routerLink="/contact-us" routerLinkActive="active">Contacts Us</a></li>
						  <li class="efect"><a href="#" routerLink="/employers-enquiry" routerLinkActive="active">Foreign Employer</a></li>
                        
            		 </ul>
				</div>
            </div>           
            </div>
          </div>
 </section>