 <div class="industry-page">
	<div class="page-banner" id="header-eliment">
		<div class="container">
           <div class="banner-contents">             
			 <h2>Industry We Serve</h2>
			 <div class="d-flex align-items-center">
				<a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Industry We Serve</p>
             </div>
		   </div>  
		</div>       
    </div>
	<div class="industry-area">
	<div class="container">
	  <div class="col-md-12">
	    <h4 class="main-heading-center"> Industry We Serve</h4>
	  </div>
		<div class="row mb-3">
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry1.jpg"/>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Building  & Construction</h4>
				<p>Construction industry is the solid foundation for any economy. With the quick aut
				omation and computerization, this industry is well balanced in terms of equipment and has appeared as most dynamic as well as advancing
				industry sector. With the industry facing unparalleled growth in the recent years, the growth and modernization procedures employed by the 
				enterprises involve use of new skill</p>
			   </div>
			   
			</div>
		  </div>		  
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry2.jpg"/>
				 <div class="practic-area-icon">
				   <i class="flaticon-care"></i>
				 </div>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Oil & Gas Industry</h4>
				 <p>Oil and Gas Industries contribute a lot in the economic development worldwide. The industry is a convincing and sophisticated business.
				 It supplies two-thirds of the world’s energy which also runs the global economy. Oil and gas companies are the most important sectors, which need
				 efficient & great manpower to distribute their services.</p>
			   </div>
			   
			</div>
		  </div>
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry3.jpg"/>
				 <div class="practic-area-icon">
				   <i class="flaticon-care"></i>
				 </div>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Hotels & Restaurants</h4>
				 <p>The Hotel industry is a significant part of the hospitality industry. Being associated with the travel and tourism sector, 
				 it has become one of the leading contributors to National and World economies. These are amongst the major aspects of a nation’s infrastructure too. 
				 With expanding international trade and business, this service industry has more in store for the upcoming years.</p>
			   </div>
			   
			</div>
		  </div>
	   </div>
	   <div class="row mb-3">
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry4.jpg"/>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Hospitality</h4>
				<p>In the hospitality industry high levels of being courteous and focused service is what makes the business a success or a failure. 
				This makes hiring and the recruitment process for a new hospitality company very important to the successful running of the restaurant. We understand 
				this and have adopted recruitment systems and processes that screen the right mix of skills and attitude to select the best hospitality staff from the 
				available pool of candidates.</p>
			   </div>
			   
			</div>
		  </div>
		  
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry5.jpg"/>
				 <div class="practic-area-icon">
				   <i class="flaticon-care"></i>
				 </div>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Transportation</h4>
				 <p>The transportation sector is an important industry sector in the economy that deals with the movement of people and products. These include companies such as airlines, trucking, railroads, shipping, and logistics firms, as well as those that provide transportation infrastructure.  Technically, transportation is a sub-group of the industrials sector according to the Global Industry Classification Standard (GICS).</p>
			   </div>
			   
			</div>
		  </div>
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry6.jpg"/>
				 <div class="practic-area-icon">
				   <i class="flaticon-care"></i>
				 </div>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Electro-Mechanical Industry</h4>
				 <p>The electro-mechanical technicians primarily work in manufacturing industries, utilities, and research & development fields. 
				 Their work profile includes the combination of mechanical technology knowledge with electrical and electronic circuits. Electrical engineers 
				 in designing and testing robotics equipment. They are mainly responsible for service, maintenance, installation, automated, servo-mechanical 
				 and electro-mechanical equipment in various industries.</p>
			   </div>			   
			</div>
		  </div>
	   </div>
	  <div class="row mb-3">
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry7.jpg"/>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Banking & Finance</h4>
				<p>The banking industry handles finances in a country including cash and credit. Banks are the institutional bodies that accept deposits and grant credit to the entities and play a major role in maintaining the economic stature of a country. Given their importance in the economy, banks are kept under strict regulation in most of the countries.</p>
			   </div>			   
			</div>
		  </div>		  
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry8.jpg"/>
				 <div class="practic-area-icon">
				   <i class="flaticon-care"></i>
				 </div>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Nursing & Paramedical Staffing</h4>
				 <p>The Health care Industry is an amalgamation of multiple Industries such as Pharmaceuticals, Health Insurance, Manufacturers of equipment’s, 
				 hospitality management etc. Demand & Supply of these Industries have increased, due to wider awareness of people’s health concerns.</p>
			   </div>
			   
			</div>
		  </div>
		   <div class="col-md-4">
			<div class="practic-area-box">
			   <div class="practic-area-img">
				 <img src="../../../assets/img/industry9.jpg"/>
				 <div class="practic-area-icon">
				   <i class="flaticon-care"></i>
				 </div>
			   </div>
			   <div class="practic-area-caption">
				 <h4>Semi / Un-Skilled Workars / Labour Category</h4>
				 <p>We supply all category Labour workforces , some of them are listed here: Mason, Plumber, Carpenter, Electrician, Steel Fixer, Rigger, Ductman's Insulators, 
				 Heavy Equipment Operators, Heavy Driver, Painters – Spray/Brush, Welders – X-Ray/Pipe/Structural, Pipe Fitters,Sheet Metal Workers, Tile Installers, 
				 Cement Masons, Insulation Workers.</p>
			   </div>			   
			</div>
		  </div>
	   </div>
	</div>
  </div>  
 </div>