<div class="whu-us-page">
	<div class="page-banner" id="header-eliment">
        <div class="container">
           <div class="banner-contents">             
             <h2>Why Us</h2>
             <div class="d-flex align-items-center">
                <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Why Us</p>
           </div>
           </div>     
        </div>    
    </div>
    <div class="profile-area whyus">
        <div class="container">
         <h4 class="profile-head my-4"> Why Us</h4>
         <p class="mt-4">We have an excellent track record for providing outstanding services to various reputed companies spread across various continents. Specifically, we have a large clientele spread over the entire Middle- East, involved in large Engineering and Construction projects, Sea Ports and Marine Services, Hospitality, Manufacturing, Retail, Oil and Gas, Banking, Administration, Agriculture, Printing, Power and Automobile sector.</p>
         <p>Rollic India is managed by professionals with extensive work experience in Fortune 500 companies and our work ethics, integrity and commitment have made us one of the most reliable employment solutions providers in the industry today. We have a team of experienced domain specialists for selection of candidates using our network of offices in India, Nepal and Sri Lanka.</p>
         <h5 class="mt-4"><strong>ORIGINS</strong></h5>
		 <p>Our 3 decades long successful experience in the industry of international recruitment has taught us some valuable lessons on commitment, transparency and the value of time management. This made us re-think our already successful but old business model.</p>
         <h5><strong>MISSION STATEMENT</strong></h5>
		 <p>Rollic india primary role is to place suitable candidates into permanent roles for the Oil and Gas, Construction, Power, Infrastructure, Automobile, Logistics, Hospitality, Technology, Defense and other related sectors.</p>
		 <h5><strong>OUR VISION AND GOALS</strong></h5>
		 <p>Our Vision is to bring to our clients the latest recruitment model which will save time and ensure the selection of candidates who meet all the parameters of client expectations.</p>
		 <h5><strong>EXPERIENCE</strong></h5>
		 <p>Our management team has extensive experience in recruitment, as they have been actively involved in recruiting more than 50,000 personnel of various categories, abroad in their role as Senior Management with the previous company.</p>	
		 <h5><strong>QUALITY POLICY</strong></h5>
		 <p>Quality of Rollic India’s service is the foundation of the company. We use our long experience in the industry, advanced tools, techniques and fair practices to track all of our projects to ensure that we deliver strong and quality recruitment solutions to all our clients</p>
		 <h5><strong>CORE VALUES</strong></h5>
		 <p><strong>Collaboration</strong> – Collaborating with clients and job seekers to match and deliver the best.</p>	
		 <p><strong>Commitment </strong> – Commitment to provide the best possible service and other initiatives that impacts the functioning of clients and lives of job seekers.</p>	
		 
        </div>
    </div>
</div>