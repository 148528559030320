import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-track-status',
  templateUrl: './track-status.component.html',
  styleUrls: ['./track-status.component.css']
})
export class TrackStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
