<div class="post-resume-page">
	<div class="page-banner" id="header-eliment">
		<div class="container">
           <div class="banner-contents">             
			 <h2>Candidates Registration</h2>
			 <div class="d-flex align-items-center">
				<a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Candidates Registration</p>
             </div>
		   </div>  
		</div>       
    </div>
	<div class="post-resume-area">
        <div class="container">
            <div class="col-md-12">
                <h4 class="post-main-heading"> We receive hundreds of jobs per day , so if you can’t find the perfect job right now it doesn’t mean we won’t have it tomorrow. Register with us and we will be in touch with the latest jobs relevant to you.</h4>
            </div>
            <div class="form-stepper">
            <mat-horizontal-stepper>
                <mat-step>
                    <ng-template matStepLabel>FILL YOUR PERSONAL INFORMATION</ng-template>
                    <div class="form-heading-bar">LOGIN INFORMATION</div>
                    <div class="shop-form">
                        <div style="clear:both;"><br></div> 
                        <span style="color:#FF0000; font-size:11px;"></span>
                        <div class="row clearfix">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="row clearfix">
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Email Id <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="emailIdId"></span></div>
                                        <input type="text" name="emailId" id="emailId" value="" >
                                    </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label"> Password <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="passId"></span> </div>
                                        <input type="password" name="pass" id="pass" value="">
                                    </div>
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Confirm Password<sup>*</sup> <span style="font-size:11px; color:#FF0000" id="cpassId"></span></div>
                                        <input type="password" name="cpass" id="cpass" value="">
                                    </div>
                                </div>
                                <div class=" form-btn-text ">
                                    <button type="submit" name="rst" id="rst" class="form-sub-btn" style="background:#6b6b96;">Send OTP</button>
                            </div>
                            </div>
                        </div> 
                    </div>
                        <div class="form-heading-bar">PERSONAL INFORMATION</div>
                        <div class="shop-form mt-3">
                            <div style="clear:both;"><br></div> 
                    
                            <div class="row clearfix">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            
                                    <div class="row clearfix">
                                    
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Position Applied For <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="posiforApplyId"></span></div>
                                            <input type="text" name="posiforApply" id="posiforApply" value="">
                                            
                                        </div>
                                    
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Category<sup>*</sup> <span style="font-size:11px; color:#FF0000" id="categoryId"></span></div>
                                            <select name="category" id="category">
                                            <option value="">Select Category *</option>
                                                <option label="Education / Teaching / Training" value="1011">Education / Teaching / Training</option>
                                                <option label="Engineering" value="31">Engineering</option>
                                                <option label="Finance / Accounts" value="1237">Finance / Accounts</option>
                                                <option label="Healthcare" value="116">Healthcare</option>
                                                <option label="Hotels &amp; Hospitality" value="1702">Hotels &amp; Hospitality</option>
                                                <option label="HR / Administration" value="1197">HR / Administration</option>
                                                <option label="IT / ITES - BPO" value="1271">IT / ITES - BPO</option>
                                                <option label="Medical / Paramedical" value="1700">Medical / Paramedical</option>
                                                <option label="Sales / Marketing" value="1225">Sales / Marketing</option>
                                                <option label="Service" value="261">Service</option>

                                            </select>
                                        
                                        </div>
                                        
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Sub Category <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="subcategoryId"></span></div>
                                        <select name="subcategory" id="subcategory">
                                                <option value="">--Select Sub Category--</option>
                                                <option label="Engineering" value="31">Engineering</option>
                                                <option label="Finance / Accounts" value="1237">Finance / Accounts</option>
                                                <option label="Healthcare" value="116">Healthcare</option>
                                                <option label="Hotels &amp; Hospitality" value="1702">Hotels &amp; Hospitality</option>
                                                <option label="HR / Administration" value="1197">HR / Administration</option>
                                                <option label="IT / ITES - BPO" value="1271">IT / ITES - BPO</option>
                                                <option label="Medical / Paramedical" value="1700">Medical / Paramedical</option>
                                                <option label="Sales / Marketing" value="1225">Sales / Marketing</option>
                                                <option label="Service" value="261">Service</option>
                                                    
                                            </select>
                                            
                                        </div>
                                        
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Specialization  <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="specilizationId"></span></div>
                                            <select name="specilization" id="specilization">
                                                <option value="">--Select Specialization--</option>
                                                <option label="Engineering" value="31">Engineering</option>
                                                <option label="Finance / Accounts" value="1237">Finance / Accounts</option>
                                                <option label="Healthcare" value="116">Healthcare</option>
                                                <option label="Hotels &amp; Hospitality" value="1702">Hotels &amp; Hospitality</option>
                                                <option label="HR / Administration" value="1197">HR / Administration</option>
                                                <option label="IT / ITES - BPO" value="1271">IT / ITES - BPO</option>
                                                <option label="Medical / Paramedical" value="1700">Medical / Paramedical</option>
                                                <option label="Sales / Marketing" value="1225">Sales / Marketing</option>
                                                <option label="Service" value="261">Service</option>
                                                    
                                            </select>
                                            
                                            
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> First Name <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="fnameId"></span> </div>
                                            <input type="text" name="fname" id="fname" value="">
                                            
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Middle Name  </div>
                                            <input type="text" name="mname" id="mname" value="">
                                            
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Last Name <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="lnameId"></span> </div>
                                            <input type="text" name="lname" id="lname" value="">
                                            
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Father Name  </div>
                                            <input type="text" name="fathname" id="fathname" value="">
                                            
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Mother Name  </div>
                                            <input type="text" name="mothName" id="mothName" value="">
                                            
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Nationality <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="natinalityId"></span> </div>
                                            <select name="natinality" id="natinality">
                                                <option value="">--Select Nationality--</option>
                                                    <option label="Afghanistan" value="183">Afghanistan</option>
                                                    <option label="Aland Islands" value="184">Aland Islands</option>
                                                    <option label="Albania" value="185">Albania</option>
                                                    <option label="Algeria" value="186">Algeria</option>
                                                    <option label="Andorra" value="187">Andorra</option>
                                                    <option label="Angola" value="188">Angola</option>
                                                    <option label="Anguilla" value="189">Anguilla</option>
                                                    <option label="Antarctica" value="190">Antarctica</option>
                                                    <option label="Antigua And Barbuda" value="191">Antigua And Barbuda</option>
                                                    <option label="Argentina" value="1">Argentina</option>
                                                    <option label="Armenia" value="192">Armenia</option>
                                                    <option label="Aruba" value="2">Aruba</option>
                                                    <option label="Australia" value="3">Australia</option>
                                                    <option label="Austria" value="4">Austria</option>
                                                    <option label="Azerbaijan" value="193">Azerbaijan</option>
                                                    <option label="Bahamas" value="5">Bahamas</option>
                                                    <option label="Bahrain" value="194">Bahrain</option>
                                                    <option label="Bangladesh" value="195">Bangladesh</option>
                                                    <option label="Barbados" value="6">Barbados</option>
                                                    <option label="Belarus" value="197">Belarus</option>
                                                    <option label="Belgium" value="7">Belgium</option>
                                                    <option label="Belize" value="8">Belize</option>
                                                    <option label="Benin" value="9">Benin</option>
                                                    <option label="Bermuda" value="10">Bermuda</option>
                                                    <option label="Bhutan" value="11">Bhutan</option>
                                                    <option label="Bolivia" value="12">Bolivia</option>
                                                    <option label="Bosnia And Herzegovina" value="198">Bosnia And Herzegovina</option>
                                                    <option label="Botswana" value="199">Botswana</option>
                                                    <option label="Bouvet Island" value="200">Bouvet Island</option>
                                                    <option label="Brazil" value="13">Brazil</option>
                                                    <option label="British Indian Ocean Territory" value="201">British Indian Ocean Territory</option>
                                                    <option label="Brunei Darussalam" value="202">Brunei Darussalam</option>
                                                    <option label="Bulgaria" value="203">Bulgaria</option>
                                                    <option label="Burkina Faso" value="204">Burkina Faso</option>
                                                    <option label="Burundi" value="205">Burundi</option>
                                                    <option label="Côte D'ivoire" value="27">Côte D'ivoire</option>
                                                    <option label="Cambodia" value="206">Cambodia</option>
                                                    <option label="Cameroon" value="207">Cameroon</option>
                                                    <option label="Canada" value="14">Canada</option>
                                                    <option label="Cape Verde" value="15">Cape Verde</option>
                                                    <option label="Cayman Islands" value="16">Cayman Islands</option>
                                                    <option label="Central African Republic" value="208">Central African Republic</option>
                                                    <option label="Chad" value="209">Chad</option>
                                                    <option label="Chile" value="17">Chile</option>
                                                    <option label="China" value="18">China</option>
                                                    <option label="Christmas Island" value="210">Christmas Island</option>
                                                    <option label="Cocos (keeling) Islands" value="211">Cocos (keeling) Islands</option>
                                                    <option label="Colombia" value="19">Colombia</option>
                                                    <option label="Comoros" value="20">Comoros</option>
                                                    <option label="Congo" value="21">Congo</option>
                                                    <option label="Cook Islands" value="213">Cook Islands</option>
                                                    <option label="Costa Rica" value="22">Costa Rica</option>
                                                    <option label="Croatia" value="23">Croatia</option>
                                                    <option label="Cuba" value="24">Cuba</option>
                                                    <option label="Cyprus" value="25">Cyprus</option>
                                                    <option label="Czech Republic" value="26">Czech Republic</option>
                                                    <option label="Denmark" value="28">Denmark</option>
                                                    <option label="Djibouti" value="214">Djibouti</option>
                                                    <option label="Dominica" value="29">Dominica</option>
                                                    <option label="Dominican Republic" value="30">Dominican Republic</option>
                                                    <option label="Ecuador" value="31">Ecuador</option>
                                                    <option label="Egypt" value="32">Egypt</option>
                                                    <option label="El Salvador" value="33">El Salvador</option>
                                                    <option label="Equatorial Guinea" value="215">Equatorial Guinea</option>
                                                    <option label="Eritrea" value="216">Eritrea</option>
                                                    <option label="Estonia" value="34">Estonia</option>
                                                    <option label="Ethiopia" value="35">Ethiopia</option>
                                                    <option label="Falkland Islands (malvinas)" value="217">Falkland Islands (malvinas)</option>
                                                    <option label="Faroe Islands" value="36">Faroe Islands</option>
                                                    <option label="Fiji" value="37">Fiji</option>
                                                    <option label="Finland" value="38">Finland</option>
                                                    <option label="France" value="39">France</option>
                                                    <option label="French Guiana" value="40">French Guiana</option>
                                                    <option label="French Polynesia" value="41">French Polynesia</option>
                                                    <option label="French Southern Ts" value="42">French Southern Ts</option>
                                                    <option label="Gabon" value="43">Gabon</option>
                                                    <option label="Gambia" value="44">Gambia</option>
                                                    <option label="Georgia" value="45">Georgia</option>
                                                    <option label="Germany" value="46">Germany</option>
                                                    <option label="Ghana" value="47">Ghana</option>
                                                    <option label="Gibraltar" value="48">Gibraltar</option>
                                                    <option label="Greece" value="49">Greece</option>
                                                    <option label="Greenland" value="50">Greenland</option>
                                                    <option label="Grenada" value="51">Grenada</option>
                                                    <option label="Guadeloupe" value="218">Guadeloupe</option>
                                                    <option label="Guam" value="219">Guam</option>
                                                    <option label="Guatemala" value="220">Guatemala</option>
                                                    <option label="Guernsey" value="221">Guernsey</option>
                                                    <option label="Guinea" value="222">Guinea</option>
                                                    <option label="Guinea-bissau" value="223">Guinea-bissau</option>
                                                    <option label="Guyana" value="224">Guyana</option>
                                                    <option label="Haiti" value="225">Haiti</option>
                                                    <option label="Heard Island And Mcdonald Islands" value="226">Heard Island And Mcdonald Islands</option>
                                                    <option label="Holy See (vatican City State)" value="227">Holy See (vatican City State)</option>
                                                    <option label="Honduras" value="52">Honduras</option>
                                                    <option label="Hong Kong" value="53">Hong Kong</option>
                                                    <option label="Hungary" value="54">Hungary</option>
                                                    <option label="Iceland" value="55">Iceland</option>
                                                    <option label="India" value="56">India</option>
                                                    <option label="Indonesia" value="57">Indonesia</option>
                                                    <option label="Iran" value="58">Iran</option>
                                                    <option label="Iraq" value="59">Iraq</option>
                                                    <option label="Ireland" value="60">Ireland</option>
                                                    <option label="Isle Of Man" value="228">Isle Of Man</option>
                                                    <option label="Israel" value="61">Israel</option>
                                                    <option label="Italy" value="62">Italy</option>
                                                    <option label="Jamaica" value="63">Jamaica</option>
                                                    <option label="Japan" value="64">Japan</option>
                                                    <option label="Jersey" value="229">Jersey</option>
                                                    <option label="Jordan" value="65">Jordan</option>
                                                    <option label="Kazakhstan" value="230">Kazakhstan</option>
                                                    <option label="Kenya" value="231">Kenya</option>
                                                    <option label="Kiribati" value="232">Kiribati</option>
                                                    <option label="Korea, Democratic People's Republic Of" value="233">Korea, Democratic People's Republic Of</option>
                                                    <option label="Kuwait" value="66">Kuwait</option>
                                                    <option label="Kyrgyzstan" value="234">Kyrgyzstan</option>
                                                    <option label="Lao" value="67">Lao</option>
                                                    <option label="Latvia" value="68">Latvia</option>
                                                    <option label="Lebanon" value="69">Lebanon</option>
                                                    <option label="Lesotho" value="70">Lesotho</option>
                                                    <option label="Liberia" value="71">Liberia</option>
                                                    <option label="Libyan Arab Jamahiriya" value="235">Libyan Arab Jamahiriya</option>
                                                    <option label="Liechtenstein" value="72">Liechtenstein</option>
                                                    <option label="Lithuania" value="73">Lithuania</option>
                                                    <option label="Luxembourg" value="74">Luxembourg</option>
                                                    <option label="Macau" value="75">Macau</option>
                                                    <option label="Macedonia (fyr)" value="76">Macedonia (fyr)</option>
                                                    <option label="Madagascar" value="77">Madagascar</option>
                                                    <option label="Malawi" value="78">Malawi</option>
                                                    <option label="Malaysia" value="79">Malaysia</option>
                                                    <option label="Maldives" value="80">Maldives</option>
                                                    <option label="Mali" value="81">Mali</option>
                                                    <option label="Malta" value="82">Malta</option>
                                                    <option label="Marshall Islands" value="83">Marshall Islands</option>
                                                    <option label="Martinique" value="84">Martinique</option>
                                                    <option label="Mauritania" value="85">Mauritania</option>
                                                    <option label="Mauritius" value="86">Mauritius</option>
                                                    <option label="Mayotte" value="87">Mayotte</option>
                                                    <option label="Mexico" value="88">Mexico</option>
                                                    <option label="Micronesia" value="89">Micronesia</option>
                                                    <option label="Moldova" value="90">Moldova</option>
                                                    <option label="Monaco" value="91">Monaco</option>
                                                    <option label="Mongolia" value="92">Mongolia</option>
                                                    <option label="Montserrat" value="93">Montserrat</option>
                                                    <option label="Morocco" value="94">Morocco</option>
                                                    <option label="Mozambique" value="95">Mozambique</option>
                                                    <option label="Myanmar" value="96">Myanmar</option>
                                                    <option label="Namibia" value="97">Namibia</option>
                                                    <option label="Nauru" value="98">Nauru</option>
                                                    <option label="Nepal" value="99">Nepal</option>
                                                    <option label="Netherlands" value="100">Netherlands</option>
                                                    <option label="Netherlands Antilles" value="101">Netherlands Antilles</option>
                                                    <option label="New Caledonia" value="102">New Caledonia</option>
                                                    <option label="New Zealand" value="103">New Zealand</option>
                                                    <option label="Nicaragua" value="104">Nicaragua</option>
                                                    <option label="Niger" value="105">Niger</option>
                                                    <option label="Nigeria" value="106">Nigeria</option>
                                                    <option label="Niue" value="107">Niue</option>
                                                    <option label="Norfolk Island" value="108">Norfolk Island</option>
                                                    <option label="North Korea" value="109">North Korea</option>
                                                    <option label="Norway" value="110">Norway</option>
                                                    <option label="Oman" value="111">Oman</option>
                                                    <option label="Pakistan" value="112">Pakistan</option>
                                                    <option label="Palau" value="113">Palau</option>
                                                    <option label="Palestinian Territory, Occupied" value="236">Palestinian Territory, Occupied</option>
                                                    <option label="Panama" value="114">Panama</option>
                                                    <option label="Papua New Guinea" value="237">Papua New Guinea</option>
                                                    <option label="Paraguay" value="115">Paraguay</option>
                                                    <option label="Peru" value="116">Peru</option>
                                                    <option label="Philippines" value="117">Philippines</option>
                                                    <option label="Pitcairn" value="118">Pitcairn</option>
                                                    <option label="Poland" value="119">Poland</option>
                                                    <option label="Portugal" value="120">Portugal</option>
                                                    <option label="Puerto Rico" value="121">Puerto Rico</option>
                                                    <option label="Qatar" value="122">Qatar</option>
                                                    <option label="Reunion" value="123">Reunion</option>
                                                    <option label="Romania" value="124">Romania</option>
                                                    <option label="Russian Federation" value="125">Russian Federation</option>
                                                    <option label="Rwanda" value="126">Rwanda</option>
                                                    <option label="Saint Helena" value="127">Saint Helena</option>
                                                    <option label="Saint Kitts And Nevis" value="238">Saint Kitts And Nevis</option>
                                                    <option label="Saint Lucia" value="128">Saint Lucia</option>
                                                    <option label="Saint Pierre And Miquelon" value="239">Saint Pierre And Miquelon</option>
                                                    <option label="Saint Vincent And The Grenadines" value="240">Saint Vincent And The Grenadines</option>
                                                    <option label="Samoa" value="129">Samoa</option>
                                                    <option label="San Marino" value="130">San Marino</option>
                                                    <option label="Sao Tome And Principe" value="241">Sao Tome And Principe</option>
                                                    <option label="Saudi Arabia" value="131">Saudi Arabia</option>
                                                    <option label="Senegal" value="132">Senegal</option>
                                                    <option label="Serbia And Montenegro" value="242">Serbia And Montenegro</option>
                                                    <option label="Seychelles" value="133">Seychelles</option>
                                                    <option label="Sierra Leone" value="134">Sierra Leone</option>
                                                    <option label="Singapore" value="135">Singapore</option>
                                                    <option label="Slovakia" value="136">Slovakia</option>
                                                    <option label="Slovenia" value="137">Slovenia</option>
                                                    <option label="Solomon Islands" value="138">Solomon Islands</option>
                                                    <option label="Somalia" value="139">Somalia</option>
                                                    <option label="South Africa" value="140">South Africa</option>
                                                    <option label="South Georgia" value="141">South Georgia</option>
                                                    <option label="South Korea" value="142">South Korea</option>
                                                    <option label="Spain" value="143">Spain</option>
                                                    <option label="Sri Lanka" value="144">Sri Lanka</option>
                                                    <option label="Sudan" value="145">Sudan</option>
                                                    <option label="Suriname" value="146">Suriname</option>
                                                    <option label="Svalbard And Jan Mayen" value="243">Svalbard And Jan Mayen</option>
                                                    <option label="Swaziland" value="147">Swaziland</option>
                                                    <option label="Sweden" value="148">Sweden</option>
                                                    <option label="Switzerland" value="149">Switzerland</option>
                                                    <option label="Syria" value="150">Syria</option>
                                                    <option label="Taiwan" value="151">Taiwan</option>
                                                    <option label="Tajikistan" value="152">Tajikistan</option>
                                                    <option label="Tanzania" value="153">Tanzania</option>
                                                    <option label="Thailand" value="154">Thailand</option>
                                                    <option label="Timor-leste" value="244">Timor-leste</option>
                                                    <option label="Togo" value="155">Togo</option>
                                                    <option label="Tokelau" value="156">Tokelau</option>
                                                    <option label="Tonga" value="157">Tonga</option>
                                                    <option label="Trinidad And Tobago" value="158">Trinidad And Tobago</option>
                                                    <option label="Tunisia" value="159">Tunisia</option>
                                                    <option label="Turkey" value="160">Turkey</option>
                                                    <option label="Turkmenistan" value="161">Turkmenistan</option>
                                                    <option label="Turks And Caicos" value="162">Turks And Caicos</option>
                                                    <option label="Tuvalu" value="163">Tuvalu</option>
                                                    <option label="Uganda" value="164">Uganda</option>
                                                    <option label="Ukraine" value="165">Ukraine</option>
                                                    <option label="United Arab Emirates" value="166">United Arab Emirates</option>
                                                    <option label="United Kingdom" value="167">United Kingdom</option>
                                                    <option label="United States" value="168">United States</option>
                                                    <option label="United States Minor Outlying Islands" value="245">United States Minor Outlying Islands</option>
                                                    <option label="Uruguay" value="169">Uruguay</option>
                                                    <option label="Uzbekistan" value="170">Uzbekistan</option>
                                                    <option label="Vanuatu" value="171">Vanuatu</option>
                                                    <option label="Vatican City State See Holy See" value="246">Vatican City State See Holy See</option>
                                                    <option label="Venezuela" value="172">Venezuela</option>
                                                    <option label="Viet Nam" value="173">Viet Nam</option>
                                                    <option label="Virgin Islands (brit)" value="174">Virgin Islands (brit)</option>
                                                    <option label="Virgin Islands (u.s.)" value="175">Virgin Islands (u.s.)</option>
                                                    <option label="Wallis And Futuna" value="247">Wallis And Futuna</option>
                                                    <option label="Western Sahara" value="176">Western Sahara</option>
                                                    <option label="Yemen" value="177">Yemen</option>
                                                    <option label="Yugoslavia" value="178">Yugoslavia</option>
                                                    <option label="Zaire" value="179">Zaire</option>
                                                    <option label="Zambia" value="180">Zambia</option>
                                                    <option label="Zimbabwe" value="181">Zimbabwe</option>

                                            </select>
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Place of Birth </div>
                                            <input type="text" name="placeBirth" id="placeBirth" value="">
                                            
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Date of Birth </div>
                                            <div class="date-picker">
                                                <mat-form-field>
                                                    <mat-label>dd-mm-yyyy</mat-label>
                                                    <input matInput [matDatepicker]="picker1">
                                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker1></mat-datepicker>
                                                </mat-form-field>
                                            </div>

                                            <!-- <input type="text" name="dateOfbirth" id="dateOfbirth" value="" placeholder="dd-mm-yyyy" onclick="displayCalendar(document.forms[0].dateOfbirth,'dd-mm-yyyy',this);" autocomplete="off"> -->
                                            
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Religion <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="religionId"></span> </div>
                                            <select name="religion" id="religion">
                                                <option value="">--Select Religion--</option>
                                                    <option label="Buddhist" value="33">Buddhist</option>
                                                    <option label="Christian" value="31">Christian</option>
                                                    <option label="Hindu" value="29">Hindu</option>
                                                    <option label="Jain" value="34">Jain</option>
                                                    <option label="Muslim" value="30">Muslim</option>
                                                    <option label="Sikh" value="32">Sikh</option>

                                            </select>
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Gender  <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="genderId"></span></div>
                                            <select name="gender" id="gender">
                                                <option value="">--Select Gender--</option>
                                                    <option label="Male" value="1">Male</option>
                                                    <option label="Female" value="2">Female</option>

                                            </select>
                                            
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Marital Status  <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="maritalstatusId"></span></div>
                                            <select name="maritalstatus" id="maritalstatus">
                                                <option value="">--Select Marital Status--</option>
                                                    <option label="Single" value="1">Single</option>
                                                    <option label="Married" value="2">Married</option>

                                            </select>
                                            
                                            
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Present Address </div>
                                            <textarea id="exampleFormControlTextarea1" rows="4" class="form-control"></textarea>
                                            
                                            
                                        </div>
                                        
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Permanent Address  </div>
                                            <textarea id="exampleFormControlTextarea2" rows="4" class="form-control"></textarea>
                                            
                                        </div>
                                        <div class="form-group col-md-2 col-sm-2 col-xs-12">
                                            <div class="field-label">Security Code <sup>*</sup>  <span style="font-size:11px; color:#FF0000" id="scodeId"></span></div>
                                            <input id="scode" type="text" name="scode" class="form-control" maxlength="15">
                                            
                                        </div>
                                    
                                        <div class="form-group col-md-2 col-sm-2 col-xs-12">
                                        <div class="field-label">&nbsp; </div>
                                            <img src="newcaptcha.php" class="imgcaptcha" style="height: 34px;border: 1px solid #eee;">
                                        </div>
                                    
                                </div> 
                                <div style="clear:both;"></div>
                                <div class=" form-btn-text "> 
                                        <button mat-button matStepperNext type="submit" name="smt1" id="smt1" class="form-sub-btn">Continue to next step</button>
                                        <button type="reset" name="rst" id="rst" class="form-sub-btn" style="background:#6b6b96;">Reset</button>
                                </div>
                                </div>
                            </div>    
                        </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>FILL YOUR PASSPORT DETAILS</ng-template>
                    <div class="form-heading-bar passport-heading">PASSPORT DETAILS</div>
                        <div class="shop-form">
                            <div style="clear:both;"><br></div> 
                       
                            <div class="row clearfix">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            
                                    <div class="row clearfix">
                                    
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Passport No </div>
                                            <input type="text" name="passportNo" id="passportNo" value="">
                                            
                                        </div>
                                       
                                         <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                           <div class="field-label">Date of issue</div>
                                              <input type="text" name="dateofissue" id="dateofissue" value=""  placeholder="dd-mm-yyyy">
                                           
                                        </div>
                                        
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                           <div class="field-label">Date of Expiry  </div> 	
                                          <input type="text" name="dateofexpire" id="dateofexpire" value="" placeholder="dd-mm-yyyy">
                                            
                                        </div>
                                        
                                         <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label">Place of Issue  <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="specilizationId"></span></div>
                                            <input type="text" name="placeofissue" id="placeofissue" value="">
                                            
                                            
                                        </div>
                                        <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Passport Type </div>
                                            <select name="passporttype" id="passporttype">
                                                <option value="">--Select Passport Type--</option>
                                                    <option label="ECR" value="117">ECR</option>
                                                    <option label="ECNR" value="118">ECNR</option>

                                              </select>
                                        </div>
                                         <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                            <div class="field-label"> Applied For Passport<sup>*</sup> <span style="font-size:11px; color:#FF0000" id="idproofId"></span>  </div>
                                            <input type="checkbox" name="appltforPass" id="appltforPass" value="1">
                                            
                                             <input type="text" name="idforpassport" id="idforpassport" value="" style="width:130px; float:right;">
                                            <select name="idproof" id="idproof" style="width:160px; float:right; margin-right:10px;">
                                            *  
                                                <option value="">--Select ID--</option>
                                                    <option label="Aadhar Card" value="1">Aadhar Card</option>
                                                    <option label="Voter Id " value="2">Voter Id </option>
                                                    <option label="PAN Card" value="3">PAN Card</option>
                                                    <option label="Driving License" value="4">Driving License</option>

                                              </select>
                                        </div>
                                   </div>
                                </div>
                            </div>  
                        </div>
                        <div style="clear:both;"></div>
                        <div class=" form-btn-text passport-step-btn"> 
                            <button mat-button matStepperPrevious type="submit" name="smt1" id="smt1" class="form-sub-btn">Back</button>
                            <button mat-button matStepperNext type="reset" name="rst" id="rst" class="form-sub-btn">Next</button>
                            <button type="reset" name="rst" id="rst" class="form-sub-btn" style="background:#6b6b96;">Reset</button>
                        </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>FILL YOUR EDUCATION DETAIL</ng-template>
                    <div class="form-heading-bar edu-heading">EDUCATIONAL DETAILS</div>
                    <div class="shop-form">
                        <div style="clear:both;"><br></div> 
                    
                        <div class="row clearfix">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                             <!-- <div class="col-sm-12" style="background-color:#CCCCCC; color:#000000;">EDUCATIONAL DETAILS (1)</div> -->
                                <div class="row clearfix">
                                
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Qualification  <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="qualification1Id"></span></div>
                                        <select name="qualification1" id="qualification1">
                                          <option value="">Select Qualification </option>
                                            <option label="B.A" value="3">B.A</option>
                                            <option label="B.Arch" value="10">B.Arch</option>
                                            <option label="B.B.A" value="12">B.B.A</option>
                                            <option label="B.Com" value="13">B.Com</option>
                                            <option label="B.Ed" value="14">B.Ed</option>
                                            <option label="B.Pharma" value="17">B.Pharma</option>
                                            <option label="B.Sc" value="2">B.Sc</option>
                                            <option label="B.Tech/B.E" value="18">B.Tech/B.E</option>
                                            <option label="BCA" value="11">BCA</option>
                                            <option label="BDS" value="15">BDS</option>
                                            <option label="BHM" value="16">BHM</option>
                                            <option label="BVSC" value="22">BVSC</option>
                                            <option label="Diploma" value="21">Diploma</option>
                                            <option label="LLB" value="19">LLB</option>
                                            <option label="MBBS" value="20">MBBS</option>
                                            <option label="Pursuing Graduation" value="9">Pursuing Graduation</option>
                                            <option label="Other" value="23">Other</option>
                                        

                                          </select>
                                        
                                    </div>
                                   
                                     <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label"> Specialization<sup>*</sup> <span style="font-size:11px; color:#FF0000" id="specialization1Id"></span>   </div>
                                         <input type="text" name="specialization1" id="specialization1" value="">
                                       
                                    </div>
                                    
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Duration<sup>*</sup> <span style="font-size:11px; color:#FF0000" id="duration1Id"></span></div>
                                        <input type="text" name="duration1" id="duration1" value="">
                                        
                                    </div>
                                    <div style="clear:both"></div>
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Year of Passing <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="yearofpassing1Id"></span></div>
                                        <select name="yearofpassing1" id="yearofpassing1">
                                          <option value="">Select Year </option>
                                            <option label="2020" value="2020">2020</option>
                                            <option label="2019" value="2019">2019</option>
                                            <option label="2018" value="2018">2018</option>
                                            <option label="2017" value="2017">2017</option>
                                            <option label="2016" value="2016">2016</option>
                                            <option label="2015" value="2015">2015</option>
                                            <option label="2014" value="2014">2014</option>
                                            <option label="2013" value="2013">2013</option>
                                            <option label="2012" value="2012">2012</option>
                                            <option label="2011" value="2011">2011</option>
                                            <option label="2010" value="2010">2010</option>
                                            <option label="2009" value="2009">2009</option>
                                            <option label="2008" value="2008">2008</option>
                                            <option label="2007" value="2007">2007</option>
                                            <option label="2006" value="2006">2006</option>
                                            <option label="2005" value="2005">2005</option>
                                            <option label="2004" value="2004">2004</option>
                                            <option label="2003" value="2003">2003</option>
                                            <option label="2002" value="2002">2002</option>
                                            <option label="2001" value="2001">2001</option>
                                            <option label="2000" value="2000">2000</option>
                                            <option label="1999" value="1999">1999</option>
                                            <option label="1998" value="1998">1998</option>
                                            <option label="1997" value="1997">1997</option>
                                            <option label="1996" value="1996">1996</option>
                                            <option label="1995" value="1995">1995</option>
                                            <option label="1994" value="1994">1994</option>
                                            <option label="1993" value="1993">1993</option>
                                            <option label="1992" value="1992">1992</option>
                                            <option label="1991" value="1991">1991</option>
                                            <option label="1990" value="1990">1990</option>
                                            <option label="1989" value="1989">1989</option>
                                            <option label="1988" value="1988">1988</option>
                                            <option label="1987" value="1987">1987</option>
                                            <option label="1986" value="1986">1986</option>
                                            <option label="1985" value="1985">1985</option>
                                            <option label="1984" value="1984">1984</option>
                                            <option label="1983" value="1983">1983</option>
                                            <option label="1982" value="1982">1982</option>
                                            <option label="1981" value="1981">1981</option>
                                            <option label="1980" value="1980">1980</option>
                                            <option label="1979" value="1979">1979</option>
                                            <option label="1978" value="1978">1978</option>
                                            <option label="1977" value="1977">1977</option>
                                            <option label="1976" value="1976">1976</option>
                                            <option label="1975" value="1975">1975</option>
                                            <option label="1974" value="1974">1974</option>
                                            <option label="1973" value="1973">1973</option>
                                            <option label="1972" value="1972">1972</option>
                                            <option label="1971" value="1971">1971</option>
                                            <option label="1970" value="1970">1970</option>
                                            <option label="1969" value="1969">1969</option>
                                            <option label="1968" value="1968">1968</option>
                                            <option label="1967" value="1967">1967</option>
                                            <option label="1966" value="1966">1966</option>
                                            <option label="1965" value="1965">1965</option>
                                            <option label="1964" value="1964">1964</option>
                                            <option label="1963" value="1963">1963</option>
                                            <option label="1962" value="1962">1962</option>
                                            <option label="1961" value="1961">1961</option>
                                            <option label="1960" value="1960">1960</option>
                                            <option label="1959" value="1959">1959</option>
                                            <option label="1958" value="1958">1958</option>
                                            <option label="1957" value="1957">1957</option>
                                            <option label="1956" value="1956">1956</option>
                                            <option label="1955" value="1955">1955</option>
                                            <option label="1954" value="1954">1954</option>
                                            <option label="1953" value="1953">1953</option>
                                            <option label="1952" value="1952">1952</option>
                                            <option label="1951" value="1951">1951</option>
                                            <option label="1950" value="1950">1950</option>
                                            <option label="1949" value="1949">1949</option>
                                            <option label="1948" value="1948">1948</option>
                                            <option label="1947" value="1947">1947</option>
                                            <option label="1946" value="1946">1946</option>
                                            <option label="1945" value="1945">1945</option>
                                            <option label="1944" value="1944">1944</option>
                                            <option label="1943" value="1943">1943</option>
                                            <option label="1942" value="1942">1942</option>
                                            <option label="1941" value="1941">1941</option>
                                            <option label="1940" value="1940">1940</option>
                                            <option label="1939" value="1939">1939</option>
                                            <option label="1938" value="1938">1938</option>
                                            <option label="1937" value="1937">1937</option>
                                            <option label="1936" value="1936">1936</option>
                                            <option label="1935" value="1935">1935</option>
                                            <option label="1934" value="1934">1934</option>
                                            <option label="1933" value="1933">1933</option>
                                            <option label="1932" value="1932">1932</option>
                                            <option label="1931" value="1931">1931</option>
                                            <option label="1930" value="1930">1930</option>
                                            <option label="1929" value="1929">1929</option>
                                            <option label="1928" value="1928">1928</option>
                                            <option label="1927" value="1927">1927</option>
                                            <option label="1926" value="1926">1926</option>
                                            <option label="1925" value="1925">1925</option>
                                            <option label="1924" value="1924">1924</option>
                                            <option label="1923" value="1923">1923</option>
                                            <option label="1922" value="1922">1922</option>
                                            <option label="1921" value="1921">1921</option>
                                            <option label="1920" value="1920">1920</option>

                                          </select>
                                    </div>
                                     <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">University Name</div>
                                         <input type="text" name="university1" id="university1" value="">
                                        
                                    </div>
                                     <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">University Address</div>
                                         <input type="text" name="universityaddress1" id="universityaddress1" value="">
                                    </div>
                                    <div style="clear:both"></div>
                                     <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Mode of Study<sup>*</sup> <span style="font-size:11px; color:#FF0000" id="moddeofstudy1Id"></span></div>
                                        <select name="moddeofstudy1" id="moddeofstudy1">
                                          <option value="">Select Study Mode </option>
                                            <option label="Regular" value="1">Regular</option>
                                            <option label="Distance" value="2">Distance</option>

                                          </select>
                                    </div>	
                                     <!-- <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">&nbsp;</div>
                                         <a href="javascript:void(0);" onclick="showMoreOpt();" style="font-weight:600; font-size:36px;">+</a>
                                     </div> -->
                                   
                               </div>
                            </div>
                        </div>    
        
                    </div>
                    <div class="form-heading-bar work-exp-heading">WORK EXPERIENCE</div>
                    <div class="shop-form">
                        <div style="clear:both;"><br></div> 
                   
                        <div class="row clearfix">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                             <!-- <div class="col-sm-12" style="background-color:#CCCCCC; color:#000000;">EXPERIENCE DETAILS (1)</div> -->
                                <div class="row clearfix">
                                
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <div class="field-label">Employer Name <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="employerName1Id"></span> </div>
                                        <input type="text" name="employerName1" id="employerName1" value="">
                                        
                                    </div>
                                   
                                     <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                       <div class="field-label">City <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="expcity1Id"></span> </div>
                                          <input type="text" name="expcity1" id="expcity1" value="">
                                       
                                    </div>
                                    
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                       <div class="field-label">Designation <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="designation1Id"></span>  </div> 	
                                      <input type="text" name="designation1" id="designation1" value="">
                                        
                                    </div>
                                    
                                     <div class="form-group col-md-2 col-sm-2 col-xs-12">
                                        <div class="field-label">Joining Date <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="joiningdate1Id"></span>   </div>
                                        <input type="text" name="joiningdate1" id="joiningdate1" value="" onclick="displayCalendar(document.forms[0].joiningdate1,'dd-mm-yyyy',this);">
                                        
                                        
                                    </div>
                                    <div class="form-group col-md-2 col-sm-2 col-xs-12">
                                        <div class="field-label"> Leaving Date <sup>*</sup> <span style="font-size:11px; color:#FF0000" id="leavingdate1Id"></span>  </div>
                                       <input type="text" name="leavingdate1" id="leavingdate1" onclick="displayCalendar(document.forms[0].leavingdate1,'dd-mm-yyyy',this);" value="">
                                    </div>
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <label for="exampleFormControlFile1">Upload Document</label>
                                        <input type="file" class="form-control-file" id="exampleFormControlFile1">
                                    </div>
                                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                        <label for="exampleFormControlFile1">Upload Resume</label>
                                        <input type="file" class="form-control-file" id="exampleFormControlFile1">
                                    </div>
                                   
                                     <!-- <div class="form-group col-md-2 col-sm-2 col-xs-12">
                                        <div class="field-label">&nbsp;</div>
                                       <a href="javascript:void(0);" onclick="showMoreExpOpt();" style="font-weight:bold; font-size:40px;">+</a>
                                    </div> -->
                               </div>
                               
                            </div>
                        </div>   
                    </div>
                    <div style="clear:both;"></div>
                        <div class=" form-btn-text edu-step-btn"> 
                                <button mat-button matStepperPrevious type="submit" name="smt1" id="smt1" class="form-sub-btn">Back</button>
                                <button mat-button matStepperNext type="reset" name="rst" id="rst" class="form-sub-btn">Next</button>
                                <button type="reset" name="rst" id="rst" class="form-sub-btn" style="background:#6b6b96;">Reset</button>
                        </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>DONE</ng-template>
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="firstCtrl" placeholder="Last name, First name" required>
                    </mat-form-field>
                    <div>
                    <button mat-button matStepperPrevious>Back</button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
        </div>  
    </div>
</div>