<div class="whu-us-page">
	<div class="page-banner" id="header-eliment">
        <div class="container">
           <div class="banner-contents">             
             <h2>Recruitment</h2>
             <div class="d-flex align-items-center">
                <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Recruitment</p>
           </div>
           </div> 
        </div>        
    </div>
    <div class="profile-area whyus">
        <div class="container">
         <h4 class="profile-head my-4"> Recruitment Process Outsourcing</h4>
         <p class="mt-4">Best Placement Company with talented professional team is here for you for any occasion. Providing high quality service and expertise. <br> Right from understanding the pulse of an employer to a successful deployment of the perfect candidate for the same. A.J. Placement Consultants is a one stop solution to understand and execute every step in a swift and successful recruitment drive. We not just provide advice with regards to the administrative and executive processes but also comprehensive legalities in order to overcome every department and document with regards to the approvals and affirmations in a recruitment drive.</p>
         <p>To initiate an official overseas recruitment from India, a Foreign employer must register itself under the Indian Embassy by submitting all the required documents and thus generating a FE (Foreign Employer) log in identification number and a password. The Foreign Employer, through the above mentioned Log in ID and password can access the Government of India website, www.emigrate.gov.in, and raise a demand in favor of A.J. Placement Consultants. Once a Foreign Employer raises a valid demand as per the above mentioned process, we at A.J. Placement Consultants receive an email from the Government of India about the demand raised in our favor. We acknowledge the same and initiate by sourcing the candidates.</p>
         <p>Further, as per the Indian law, the Foreign Employer needs to execute three documents in the favor of the Recruiter (A.J. Placement Consultants)</p>
         <h5 class="mt-4"><strong>DOCUMENTS REQUIRED</strong></h5>
         <h6 class="mt-4"><strong> Power of Attorney:</strong></h6>
		 <p>To authorize A.J. Placement Consultants to represent in various Government Departments and Consulates / Embassies of the respective countries on the behalf of the Foreign Employer.</p>
         <h6><strong>Demand Letter:</strong></h6>
		 <p>To fulfill the demand of the Foreign Employer as per their requirement. A Foreign Employer can mention additional terms and conditions as per their company policy in the Demand Letter.</p>
		 <h6><strong> Employment Contract:</strong></h6>
		 <p>The contract between the Foreign employer and the candidate as per the terms and conditions of the Demand Letter.</p>
         <p>On receipt of confirmed job orders and exact job descriptions of required categories, we at A.J. Placement Consultants provide a custom made recruitment plan for every demand, depending upon the above mentioned peculiarities rather than a standard cycle of operations. Our operations team consists of experienced and polished HR professionals.</p>
         <p>Our team of expert recruiters gets designated as per the project requirement to submit required shortlisted resumes as per the schedule with the help of our exclusive databank, best available job portals such as Naukri.com Timesjobs.com, Monster.com, and advertise the required categories in leading English and Regional newspapers on an all India basis i.e. Times of India, Assignment’s Abroad, Hindu, and the regional publication’s of the respective cities and also source the candidate through Head Hunting. Our specialized recruitment mangers test the responding candidates for their skills matching the job description and trade test if required.</p>
         <p>Preliminary interviews are conducted by our expert recruitment managers to provide the right candidate to the interview panel and to ensure that in limited time the best candidates are targeted.  Screening, assessment and short-listing of the candidates for final interview is carried out by our highly experienced recruiter. The candidate’s short listed by us is then presented to the client’s representative for the final selection.</p>
         <p>CV of shortlisted candidates’ are sent to the clients for their approval or the clients are invited to our offices to personally evaluate the candidates. Along with Certificate Attestation Candidates undergo their Medical formalities with Panel Doctors and their Medical Fitness Report is informed to the Clients.</p>
         <p>On receipt of the Visa documents from the Clients, Candidates Passports along with the required documents are submitted to the Consulate for Visa stamping. Visa stamped Passports are then submitted to Protector of Emigrants (POE) for Emigration clearance on the passports of the candidates.</p>
         <p>The candidates are given brief orientation about the Company, Project site and the country of placement before their departure from India. The Candidates are handed over their departures file with the copy of their Bio data form, Employment contract, Visa stamped passport with Emigration & copy of Insurance and Ticket two days prior to the date of Travel, consequently the arrival details of the candidates are informed to the Client.</p>
         <div class="process-chart">
            <img class="quality-img" src="../../../assets/img/RECRUITMENT-PROCES.jpg"/>
         </div>
         
        </div>
    </div>
</div>