import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emigration-clearance',
  templateUrl: './emigration-clearance.component.html',
  styleUrls: ['./emigration-clearance.component.css']
})
export class EmigrationClearanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('top-hide').style.display="none";
  }

}
