
 <div class="industry-page">
	<div class="page-banner" id="header-eliment">
		<div class="container">
           <div class="banner-contents">             
			 <h2>Jobs</h2>
			 <div class="d-flex align-items-center">
				<a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Jobs</p>
             </div>
		   </div>  
		</div>       
    </div>
	<div class="industry-area">
	<div class="container">
	  <div class="col-md-12">
        <h4 class="main-heading-center"> Find Your Desire Job</h4>
      </div>
      
      <div class="banner-form-area">
        <form>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Job Title">
                        <label>
                            <i class="fas fa-pen"></i>
                            <!-- <i class="fas fa-search"></i> -->
                        </label>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>
                            <i class="fas fa-map-marker-alt"></i>
                        </label>
                        <input type="text" class="form-control" placeholder="Location">
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label>
                            <i class="fas fa-map-marker-alt"></i>
                        </label>
                        <input type="text" class="form-control" placeholder="Sector">
                    </div>
                </div>
               <!--<div class="col-lg-4">
                    <div class="form-group">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                              Dropdown button
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" href="#">Action</a>
                              <a class="dropdown-item" href="#">Another action</a>
                              <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                          </div>
                    
                    </div>
                </div>-->
            </div>
            <button type="submit" class="btn banner-form-btn"><i class="fas fa-search"></i> Search</button>
        </form>
    </div>
    <div class="jobs-section">
        <div class="row job-row">
            <div class="col-lg-6 mix web ui" style="display: inline-block;" data-bound="">
                <div class="job-item">
                    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>UI/UX Designer</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-calendar-alt"></i>
                                    12 Sep 2020
                                </li>
                                <li>
                                    <i class="fas fa-rupee-sign"></i>
                                    $20k - $25k
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    Saudi Arabia
                                </li>
                            </ul>
                        </div>
                        <div class="job-inner-btn aboutus-btn mt-4">
                            <a href="#">View</a>
                            <a href="#">Apply</a>
                        </div>
                    </div>
                    <div class="job-img">
                        <img src="../../../assets/img/job.jpg" alt="Job">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 mix ui web" style="display: inline-block;" data-bound="">
                <div class="job-item">
                    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Android Developer</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-calendar-alt"></i>
                                    12 Sep 2020
                                </li>
                                <li>
                                    <i class="fas fa-rupee-sign"></i>
                                    $20k - $25k
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    Dubai
                                </li>
                            </ul>
                        </div>
                        <div class="job-inner-btn aboutus-btn mt-4">
                                <a href="#">View</a>
                                <a href="#">Apply</a>
                        </div>
                    </div>
                    <div class="job-img">
                        <img src="../../../assets/img/job1.jpg" alt="Job">
                    </div>
                </div>
            </div>
        </div>
        <div class="row job-row">
            <div class="col-lg-6 mix web ui" style="display: inline-block;" data-bound="">
                <div class="job-item">
                    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>UI/UX Designer</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-calendar-alt"></i>
                                    12 Sep 2020
                                </li>
                                <li>
                                    <i class="fas fa-rupee-sign"></i>
                                    $20k - $25k
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    Saudi Arabia
                                </li>
                            </ul>
                        </div>
                        <div class="job-inner-btn aboutus-btn mt-4">
                            <a href="#">View</a>
                            <a href="#">Apply</a>
                        </div>
                    </div>
                    <div class="job-img">
                        <img src="../../../assets/img/job2.jpg" alt="Job">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 mix ui web" style="display: inline-block;" data-bound="">
                <div class="job-item">
                    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Android Developer</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-calendar-alt"></i>
                                    12 Sep 2020
                                </li>
                                <li>
                                    <i class="fas fa-rupee-sign"></i>
                                    $20k - $25k
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    Dubai
                                </li>
                            </ul>
                        </div>
                        <div class="job-inner-btn aboutus-btn mt-4">
                                <a href="#">View</a>
                                <a href="#">Apply</a>
                        </div>
                    </div>
                    <div class="job-img">
                        <img src="../../../assets/img/job3.jpg" alt="Job">
                    </div>
                </div>
            </div>
        </div>
        <div class="row job-row">
            <div class="col-lg-6 mix web ui" style="display: inline-block;" data-bound="">
                <div class="job-item">
                    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>UI/UX Designer</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-calendar-alt"></i>
                                    12 Sep 2020
                                </li>
                                <li>
                                    <i class="fas fa-rupee-sign"></i>
                                    $20k - $25k
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    Saudi Arabia
                                </li>
                            </ul>
                        </div>
                        <div class="job-inner-btn aboutus-btn mt-4">
                            <a href="#">View</a>
                            <a href="#">Apply</a>
                        </div>
                    </div>
                    <div class="job-img">
                        <img src="../../../assets/img/job4.jpg" alt="Job">
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 mix ui web" style="display: inline-block;" data-bound="">
                <div class="job-item">
                    
                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Android Developer</h3>
                            <ul>
                                <li>
                                    <i class="fas fa-calendar-alt"></i>
                                    12 Sep 2020
                                </li>
                                <li>
                                    <i class="fas fa-rupee-sign"></i>
                                    $20k - $25k
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    Dubai
                                </li>
                            </ul>
                        </div>
                        <div class="job-inner-btn aboutus-btn mt-4">
                                <a href="#">View</a>
                                <a href="#">Apply</a>
                        </div>
                    </div>
                    <div class="job-img">
                        <img src="../../../assets/img/job-default.jpg" alt="Job">
                    </div>
                </div>
            </div>
        </div>
    </div>

	</div>
  </div>  
 </div>