 <div class="footer-section">
       <div class="container">
         <div class="footer-section-row d-flex justify-content-between flex-wrap">
              <div class="col-lg-3 col-md-3 col-12">
                <div class="footer-widget about-widget">
                   <!-- <figure class="footer-logo">
                     <a href="#"><img src="assets/img/logo.png"></a>
                   </figure> -->
                   <h2>ROLLIC INDIA</h2>
                   <p>Rollic India is a leading brand in International recruitment consultancy for last 15 years. we have been dully approved by Ministry of Overseas Indian Affairs, Government of India.</p>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12">
                <div class="footer-widget link-widget">
                   <h2>USEFUL LINK</h2>
                   <ul class="link-list">
                     <li><a href="#">Home</a></li>
                     <li><a href="#">Company Profile</a></li>
                     <li><a href="#">Services</a></li>
                     <li><a href="#">Jobs</a></li>
                     <li><a href="#">Recruitment</a></li>  
                     <li><a href="#">Contact Us</a></li>  
                   </ul>
                </div>
              </div>
            <div class="col-lg-3 col-md-3 col-12">
              <div class="footer-widget link-widget">
                <h2>OUR SERVICES</h2>
                <ul class="link-list">
                  <li><a href="#">Recruitment</a></li>
                  <li><a href="#">Visa Facilitation Services</a></li>
                  <li><a href="#">Emigration Clearance</a></li>
                  <li><a href="#">Document Attestation</a></li>
                  <li><a href="#">Embassy Attestation</a></li>  
                  <li><a href="#">HRD Document Attestation</a></li>  
                  <!-- <li><a href="#">MEA Attestation</a></li>
                  <li><a href="#">MEA Apostille</a></li>
                  <li><a href="#">Flight Reservations</a></li> -->
                </ul>
             </div>
            </div>   
            <div class="col-lg-4 col-md-4 col-12">
              <div class="footer-widget contact-widget">
                <h2>GET IN TOUCH</h2>
                <ul class="link-list">
                  <li class="list-content d-flex">
                    <span style="font-size:23px"><i class="fas fa-map-marker-alt"></i></span>
                    <p>A-4, First Floor, Taimoor Nagar, New Delhi-110065</p>
                  </li>
                  <li class="list-content d-flex">
                    <span style="font-size:23px"><i class="fas fa-mobile-alt"></i></span>
                    <p>For any information :- <br> +91-11-26922622, +91-11-226922422, +91-9313622422</p>
                  </li>
                  <li class="list-content d-flex">
                    <span><i class="far fa-envelope"></i></span>
                    <p>info@rollic.com</p>
                  </li>
                
                </ul>
                <ul class="social-icon">
                  <li><a class="icon facebook" href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a class="icon twitter" href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a class="icon linkedin" href="#"><i class="fab fa-linkedin-in"></i></a></li>
				          <li><a class="icon youtube" href="#"><i class="fab fa-youtube"></i></a></li>

                </ul>
              </div>
            </div>      

        </div>
       </div>
 </div>  
     <div class="copyright text-center">
       <p>All Rights Reserved © Rollic India</p>
     </div>
