<div class="visa-service-page">   
    <div class="page-banner" id="header-eliment">
      <div class="container">
            <div class="banner-contents">             
              <h2>Visa Facilitation Services</h2>
              <div class="d-flex align-items-center">
                <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Visa Facilitation Services</p>
            </div>
            </div>  
      </div>       
    </div>
     <div class="profile-area">
         <div class="container">
          <h4 class="my-4 profile-head"> Visa Facilitation Services</h4>
          <p>
           <img class="section-img visa-img" src="../../../assets/img/visa.jpg"/>
           Our consultancy services do not end up with a selection of right, eligible candidates, but also we take pride to provide effective Visa assistance services to the selected candidates. Through our experience in processing thousands of travel documents, we guaranteed to handle the visa process in an accurate and professional manner.
           We deal with stamping of Visit Visa, Family Visa, Employment Visa and Residence Visa  (Family Visa Processing for Spouse and Child of Workers ) working abroad.</p>
          <p>We have well-trained and experienced professional in our team who keeps a close liaison with various embassies, Indian Mission abroad, POE Offices, MEA by which they help the candidates in processing all required documents for the visa. You will find speedy yet professional Visa arrangement services at Rollic India. We provide reliable services in which you will find complete clarity of each and every detail</p>
          <p class="font-weight-bold">Country: Kingdom of Saudi Arabia</p>
          <h5 class="mt-4"><strong>Documents Required for Employment VISA ( DELHI SUBMISSION) :</strong></h5>
          <ul class="mb-3 list-rules">
            <li>Original Passport of candidate ( Passport must have a validity of minimum six months)</li>
            <li>Offer Letter/Contract from Employer</li>
            <li>Block VISA from Sponsar</li>
            <li>Medical report from a panel doctor approved by GAMCA Medical Centers which is mandatory for all candidate.</li>
            <li>PCC ( Police Clearance Certificate) issued from Passport office and attested from MEA (Ministry of External Affairs)</li>
            <li>Original Experience (Relevant to your allotted visa category) attested from Saudi Embassy, New Delhi</li>
            <li>Original Degree attested from Saudi Culture Attache and Saudi Embassy, New Delhi  ( In case of professional visa category )</li>
            <li>Exit paper( If been to Saudi Arabia on Employment Visa before)</li>
            <li>(Two) passport sized white background color photographs.</li>
          </ul>
          <h5 class="my-3"><strong>Documents Required for Employment VISA ( MUMBAI SUBMISSION) :</strong></h5>
           <ul class="mb-3 list-rules">
            <li>Original Passport of candidate ( Passport must have a validity of minimum six months) </li>
            <li>Block VISA from Sponsar</li>
            <li>Medical report from a panel doctor approved by GAMCA Medical Centers which is mandatory for all candidate.</li>
            <li>Original Degree attested from Saudi Culture Attache and Saudi Embassy, New Delhi  ( In case of professional visa category )</li>
            <li>Exit paper( If been to Saudi Arabia on Employment Visa before)</li>
            <li>(Two) passport sized white background color photographs.</li>
           </ul>
           <h5 class="my-3"><strong>Documents Required for Business/Commercial Visit ( Single & Multiple Entry VISA):</strong></h5>
           <ul class="mb-3 list-rules">
            <li>Invitation Letter of Saudi Company</li>
            <li>C R copy of Saudi Company</li>
            <li>Covering Letter of Indian Company</li>
            <li>Chamber of Commerce Letter </li>
            <li>Degree Copy</li>
            <li>Experience Letter (If technician or other lower category)</li>
            <li>Original Passport of candidate. ( Passport must have a validity of minimum six months)</li>
            <li>(Two) passport sized white background color photographs.</li>
            <li>Candidate Email Id and Mobile No </li>
           </ul>
           <h5 class="my-3"><strong>Documents required for Family Joining (Residence) Visa:</strong></h5>
           <ul class="mb-3 list-rules">
            <li>VISA Copy</li>
            <li>Iqama copy of sponsor</li>
            <li>Sponsor’s passport copy (first and last page)</li>
            <li>Sponsor Email Id and Mobile No</li>
            <li>Marriage Certificate (If wife)  **Original Marriage certificate and translation copy attested from Saudi Embassy is required if the (Sponsor) name is not mentioned in the passport.</li>
            <li>Birth Certificate (If Child)</li>
            <li>Medical report from a panel doctor approved by GAMCA Medical Centers which is mandatory for all candidate aged 12 and above.</li>
            <li>Polio Vaccination certificate (original) for children up to the age of 15 year old from GAMCA Medical Centers</li>
            <li>Original Passport of candidate ( Passport must have a validity of minimum six months)</li>
            <li>Separate passport for children ( Passport must have a validity of minimum six months)</li>
            <li>(Two) passport sized white background color photographs.</li>
           </ul>
           <h5 class="my-3"><strong>Documents required for Family Visit/Tourist Visa:</strong></h5>
           <ul class="mb-3 list-rules">
            <li>VISA Copy</li>
            <li>Iqama copy of sponsor</li>
            <li>Sponsor’s passport copy (first and last page)</li>
            <li>Sponsor Email Id and Mobile No</li>
            <li>Marriage Certificate (If wife)  **Original Marriage certificate and translation copy attested from Saudi Embassy is required if the (Sponsor) name is not mentioned in the passport.</li>
            <li>Birth Certificate (If Child)</li>
            <li>Original Passport of candidate ( Passport must have a validity of minimum six months)</li>
            <li>Separate passport for children ( Passport must have a validity of minimum six months)</li>
            <li>(Two) passport sized white background color photographs.</li>
           </ul>
           <h5 class="my-3"><strong> Documents required for Re-Entry Extension of VISA :</strong></h5>
           <ul class="mb-3 list-rules">
            <li>Exit Re-Entry VISA Copy</li>
            <li>Iqama copy of candidate</li>
            <li>Letter from Sponsor</li>
            <li>Jawazat Copy</li>
            <li>Original Passport of candidate ( Passport must have a validity of minimum six months)</li>
            <li>(Two) passport sized white background color photographs.</li>
           </ul>
         </div>
     </div>
 </div>