import { Component, OnInit, HostListener } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgwWowService } from 'ngx-wow';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
        title = 'ngSlick';
      
constructor(private wowService: NgwWowService) {
  
  
  }

ngOnInit(): void {
  document.getElementById('top-hide').style.display="block";
  this.wowService.init();
}   
		

  slides = [
	{img: "../../../assets/img/3.jpg", bannercaption:"We have a storage solution and unit size for every business need!"},
    {img: "../../../assets/img/2.jpg", bannercaption:"Starting from loading to unloading and maintaining the highest standards in terms of safety while in transit, we take nothing to chance"},
    {img: "../../../assets/img/slide-2-bg.jpg", bannercaption:"we pack, transport and unpack your belongings so you can relax and enjoy the journey"}

  ];
    slideConfig = {
		"autoplay": false,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  addSlide()  {
	console.log();
  }
  testimonialConfig = {
	  "autoplay": 6000,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": true
  };
  
    Industryslides = [
	{img: "../../../assets/img/service1.jpg", heading:"Construction & Building", paragraph:"Construction industry is the solid foundation for any economy. With the quick automation and computerization, this industry is well balanced in terms of equipment and has appeared as most dynamic as well as advancing  industry sector. With the industry facing unparalleled growth in the recent years, the growth and modernization procedures employed by the   enterprises involve use of new skill."},
  {img: "../../../assets/img/service2.jpg", heading:"Oil & Gas", paragraph:"Oil and Gas Industries contribute a lot in the economic development worldwide. The industry is a convincing and sophisticated business. It supplies two-thirds of the world’s energy which also runs the global economy. Oil and gas companies are the most important sectors, which need efficient & great manpower to distribute their services."},
   {img: "../../../assets/img/service3.jpg", heading:"Hotels & Restaurants", paragraph:"The Hotel industry is a significant part of the hospitality industry. Being associated with the travel and tourism sector, it has become one of the leading contributors to National and World economies. These are amongst the major aspects of a nation’s infrastructure too. With expanding international trade and business."} ,
   {img: "../../../assets/img/service4.jpg", heading:"Transportation", paragraph:"The transportation sector is an important industry sector in the economy that deals with the movement of people and products. These include companies such as airlines, trucking, railroads, shipping, and logistics firms, as well as those that provide transportation infrastructure."} ,
  {img: "../../../assets/img/service5.jpg", heading:"Banking & Finance", paragraph:"The banking industry handles finances in a country including cash and credit. Banks are the institutional bodies that accept deposits and grant credit to the entities and play a major role in maintaining the economic stature of a country. Given their importance in the economy, banks are kept under strict regulation in most of the countries."},
  {img: "../../../assets/img/service6.jpg", heading:"Information Technology", paragraph:"The Information Technology & Information Technology Enabled Services (IT-ITeS) sector is a field which is undergoing rapid evolution and is changing the shape of Indian business standards. This sector includes software development, consultancies, software management, online services and business process outsourcing (BPO)."},
	{img: "../../../assets/img/service7.jpg", heading:"Electrical & Mechanical", paragraph:"The electro-mechanical technicians primarily work in manufacturing industries, utilities, and research & development fields. Their work profile includes the combination of mechanical technology knowledge with electrical and electronic circuits. Electrical engineers in designing and testing robotics equipment."},
	{img: "../../../assets/img/service8.jpg", heading:"Health Care", paragraph:"The Health care Industry is an amalgamation of multiple Industries such as Pharmaceuticals, Health Insurance, Manufacturers of equipment’s, hospitality management etc. Demand & Supply of these Industries have increased, due to wider awareness of people’s health concerns. The main motto is to provide the best possible treatment."},
  {img: "../../../assets/img/service9.jpg", heading:"Nursing & Paramedical Staffing", paragraph:"The Health care Industry is an amalgamation of multiple Industries such as Pharmaceuticals, Health Insurance, Manufacturers of equipment’s, hospitality management etc. Demand & Supply of these Industries have increased, due to wider awareness of people’s health concerns."},
  {img: "../../../assets/img/service10.jpg", heading:"Agriculture", paragraph:"Agriculture, known also as husbandry or farming, is the science of cultivating plants, animals, and other life forms for food, fiber, and fuel. The agricultural industry, which includes enterprises engaged in growing crops, raising fish and animals, and logging wood, encompasses farms, dairies, hatcheries, and ranches."},

  
  ];
  
    IndustryslideConfig = {
		// "autoplay": 2000,
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": false,
    'responsive': [
      {
        'breakpoint': 767,
         'settings': {
          'slidesToShow': 1
                }
              },
              {
                "breakpoint": 992,
                "settings": {
                  "slidesToShow": 3
                }
              },
            ]
    // "infinite": true
  };

 thumnailslides = [
	{img: "../../../assets/img/cli1.jpg"},
    {img: "../../../assets/img/cli2.jpg"},
    {img: "../../../assets/img/cli3.jpg"},
	{img: "../../../assets/img/cli4.jpg"},
	{img: "../../../assets/img/cli5.jpg"},
	{img: "../../../assets/img/cli6.jpg"},
	{img: "../../../assets/img/cli7.jpg"},
	{img: "../../../assets/img/cli8.jpg"},
	{img: "../../../assets/img/cli9.jpg"},
	{img: "../../../assets/img/cli10.png"},
	{img: "../../../assets/img/cli11.jpg"}
  ];
  
    thumnailslideConfig = {
		"autoplay": 1000,
	    "slidesToShow": 6,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": false,
    "infinite": true,
    'responsive': [
      {
        'breakpoint': 767,
         'settings': {
          'slidesToShow': 3
                }
              },
              {
                "breakpoint": 1024,
                "settings": {
                  "slidesToShow": 4
                }
              },
            ]
  };

 
  

 /*    head = document.getElementById("tophead");

	window.onscroll = function(){
		if(document.body.scrollTop > 90 || document.documentElement.scrollTop > 90){
	head.style.display = "block";
	}
	else {
	head.style.display = "none";
	}
	}; */

 

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 250) {
       let element = document.getElementById('tophead');
       element.classList.add('sticky-nav');
     } else {
      let element = document.getElementById('tophead');
        element.classList.remove('sticky-nav'); 
     }
  }
   	
}
