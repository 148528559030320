<div class="contactus-page">
	<div class="page-banner" id="header-eliment">
        <div class="container">
           <div class="banner-contents">             
             <h2>Contact US</h2>
             <div class="d-flex align-items-center">
                <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Contact US</p>
             </div> 
           </div>
        </div>            
	</div>
	<div class="contactUs-page-body">
            <div class="container">
                <div class="contact-page-head text-center mb-3">
                    <h4 class="main-heading-center"> Business Enquiries</h4>
                    <p>Explore how we can help you with your storage warehousing & Logistics needs</p>
                  </div>
                <div class="contactus-row d-flex mt-5">
                     <div class="col-lg-6 col-md-6 col-12">
                         <div class="query-box wow bounceInUp center">
                            <div class="send-query d-flex align-items-center">
                                <i class="fa fa-envelope"></i>
                                <p class="box-icon-head">Send us a message</p>
                            </div>
                            <div class="box-content">
                                <p class="mt-2">To contact us via email, please complete our online contact form. Required fields are marked with *. We aim to reply to all email enquiries within 2 hours (sent within our working hours of 9am-6pm, Mon-Fri).</p>
                                <div class="query-form">
                                    <form>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Full Name*</label>
                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Full Name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Phone Number*</label>
                                                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Phone Number">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Email address*</label>
                                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email id">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1">Subject</label>
                                                    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Subject">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Message</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Type your message..."></textarea>
                                        </div>
                                    
                                        <button type="submit" class="get-started">Submit Query</button>
                                    </form>
                                </div>
                            </div>
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-6 col-12">
                        <div class="contact-sm-box wow slideInDown">
                            <div class="box-head d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <i class="fa fa-phone"></i>
                                    <p class="box-icon-head">Telephone</p>
                                </div>
                                
                            </div>
                            <div class="sm-box-para">
                                <p>To contact the Encash Mobile Team by phone, please call us between the hours of 9am-10pm, Mon-Sat.</p>
                            </div>
                            <div class="contact-detail">
                                <h1>+91- 011-26922622, 26922422</h1>
                            </div>
                        </div>
                        <div class="contact-sm-box wow slideInDown">
                            <div class="box-head d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <p class="box-icon-head">Address</p>
                                </div>
                            </div>
                            <div class="sm-box-para">
                                <p>A-4 First Floor, Taimoor Nagar, New Delhi-110065</p>
                            </div>
                        </div>
                        <div class="contact-sm-box wow slideInDown">
                            <div class="box-head d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <i class="fa fa-envelope"></i>
                                    <p class="box-icon-head">Email</p>
                                </div>
                            </div>
                            <div class="sm-box-para">
                                <p class="mb-0"> info@rollic.com</p>
                                <!-- <p class="mt-0">info@poweroffactorils.com</p> -->
                            </div>
                        </div>

                     </div>


                </div>
            </div>
            <div class="map-area">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.003127751119!2d77.2652571150495!3d28.569668793674204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce399fe37a7ef%3A0x15c1c3bc1a0e166c!2sROLLIC%20INDIA!5e0!3m2!1sen!2sin!4v1595923783868!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>

        </div>
</div>