<div class="employer-enquiry-page">
	<div class="page-banner" id="header-eliment">
		<div class="container">
           <div class="banner-contents">             
			 <h2>Foreign Employer</h2>
			 <div class="d-flex align-items-center">
				<a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> FOREIGN EMPLOYER</p>
             </div>
		   </div>  
		</div>       
    </div>
	<div class="employer-enquiry-section">
	<div class="container">
        <div class="contact-page-head text-center mb-3">
            <h4 class="main-heading-center"> Foreign Employer</h4>
            <p >We welcome your visit to our website and are here to assist you with all of your manpower needs.</p>
        </div>
      <div class="form-section">
        <div class="row clearfix">
            <form name="frm" id="frm" action="#" method="post"> 
             <!--Content Column-->
             <div class="content-column col-sm-12 col-xs-12">
                <div class="shop-form">
                    <div style="clear:both;"></div>
                    <span style="color:#FF0000; font-size:11px;"></span>
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                             <div class="row clearfix">
                                <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                    <div class="field-label">Company Name  <sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="usrNameId"></span> </div>
                                    <input type="text" name="usrName" id="usrName" value="">
                                </div>
                                 <div class="form-group col-md-4 col-sm-4 col-xs-12">
                                  <div class="field-label">Select Country  <sup>*  </sup><span style="font-size:11px; color:#FF0000" id="countryId"></span></div>
                                    <select name="country" id="country" value=" ">
                                        <option value="">--Select Country--</option>
                                            <option label="Afghanistan" value="183">Afghanistan</option>
                                        <option label="Aland Islands" value="184">Aland Islands</option>
                                        <option label="Albania" value="185">Albania</option>
                                        <option label="Algeria" value="186">Algeria</option>
                                        <option label="Andorra" value="187">Andorra</option>
                                        <option label="Angola" value="188">Angola</option>
                                        <option label="Anguilla" value="189">Anguilla</option>
                                        <option label="Antarctica" value="190">Antarctica</option>
                                        <option label="Antigua And Barbuda" value="191">Antigua And Barbuda</option>
                                        <option label="Argentina" value="1">Argentina</option>
                                        <option label="Armenia" value="192">Armenia</option>
                                        <option label="Aruba" value="2">Aruba</option>
                                        <option label="Australia" value="3">Australia</option>
                                        <option label="Austria" value="4">Austria</option>
                                        <option label="Azerbaijan" value="193">Azerbaijan</option>
                                        <option label="Bahamas" value="5">Bahamas</option>
                                        <option label="Bahrain" value="194">Bahrain</option>
                                        <option label="Bangladesh" value="195">Bangladesh</option>
                                        <option label="Barbados" value="6">Barbados</option>
                                        <option label="Belarus" value="197">Belarus</option>
                                        <option label="Belgium" value="7">Belgium</option>
                                        <option label="Belize" value="8">Belize</option>
                                        <option label="Benin" value="9">Benin</option>
                                        <option label="Bermuda" value="10">Bermuda</option>
                                        <option label="Bhutan" value="11">Bhutan</option>
                                        <option label="Bolivia" value="12">Bolivia</option>
                                        <option label="Bosnia And Herzegovina" value="198">Bosnia And Herzegovina</option>
                                        <option label="Botswana" value="199">Botswana</option>
                                        <option label="Bouvet Island" value="200">Bouvet Island</option>
                                        <option label="Brazil" value="13">Brazil</option>
                                        <option label="British Indian Ocean Territory" value="201">British Indian Ocean Territory</option>
                                        <option label="Brunei Darussalam" value="202">Brunei Darussalam</option>
                                        <option label="Bulgaria" value="203">Bulgaria</option>
                                        <option label="Burkina Faso" value="204">Burkina Faso</option>
                                        <option label="Burundi" value="205">Burundi</option>
                                        <option label="Côte D'ivoire" value="27">Côte D'ivoire</option>
                                        <option label="Cambodia" value="206">Cambodia</option>
                                        <option label="Cameroon" value="207">Cameroon</option>
                                        <option label="Canada" value="14">Canada</option>
                                        <option label="Cape Verde" value="15">Cape Verde</option>
                                        <option label="Cayman Islands" value="16">Cayman Islands</option>
                                        <option label="Central African Republic" value="208">Central African Republic</option>
                                        <option label="Chad" value="209">Chad</option>
                                        <option label="Chile" value="17">Chile</option>
                                        <option label="China" value="18">China</option>
                                        <option label="Christmas Island" value="210">Christmas Island</option>
                                        <option label="Cocos (keeling) Islands" value="211">Cocos (keeling) Islands</option>
                                        <option label="Colombia" value="19">Colombia</option>
                                        <option label="Comoros" value="20">Comoros</option>
                                        <option label="Congo" value="21">Congo</option>
                                        <option label="Cook Islands" value="213">Cook Islands</option>
                                        <option label="Costa Rica" value="22">Costa Rica</option>
                                        <option label="Croatia" value="23">Croatia</option>
                                        <option label="Cuba" value="24">Cuba</option>
                                        <option label="Cyprus" value="25">Cyprus</option>
                                        <option label="Czech Republic" value="26">Czech Republic</option>
                                        <option label="Denmark" value="28">Denmark</option>
                                        <option label="Djibouti" value="214">Djibouti</option>
                                        <option label="Dominica" value="29">Dominica</option>
                                        <option label="Dominican Republic" value="30">Dominican Republic</option>
                                        <option label="Ecuador" value="31">Ecuador</option>
                                        <option label="Egypt" value="32">Egypt</option>
                                        <option label="El Salvador" value="33">El Salvador</option>
                                        <option label="Equatorial Guinea" value="215">Equatorial Guinea</option>
                                        <option label="Eritrea" value="216">Eritrea</option>
                                        <option label="Estonia" value="34">Estonia</option>
                                        <option label="Ethiopia" value="35">Ethiopia</option>
                                        <option label="Falkland Islands (malvinas)" value="217">Falkland Islands (malvinas)</option>
                                        <option label="Faroe Islands" value="36">Faroe Islands</option>
                                        <option label="Fiji" value="37">Fiji</option>
                                        <option label="Finland" value="38">Finland</option>
                                        <option label="France" value="39">France</option>
                                        <option label="French Guiana" value="40">French Guiana</option>
                                        <option label="French Polynesia" value="41">French Polynesia</option>
                                        <option label="French Southern Ts" value="42">French Southern Ts</option>
                                        <option label="Gabon" value="43">Gabon</option>
                                        <option label="Gambia" value="44">Gambia</option>
                                        <option label="Georgia" value="45">Georgia</option>
                                        <option label="Germany" value="46">Germany</option>
                                        <option label="Ghana" value="47">Ghana</option>
                                        <option label="Gibraltar" value="48">Gibraltar</option>
                                        <option label="Greece" value="49">Greece</option>
                                        <option label="Greenland" value="50">Greenland</option>
                                        <option label="Grenada" value="51">Grenada</option>
                                        <option label="Guadeloupe" value="218">Guadeloupe</option>
                                        <option label="Guam" value="219">Guam</option>
                                        <option label="Guatemala" value="220">Guatemala</option>
                                        <option label="Guernsey" value="221">Guernsey</option>
                                        <option label="Guinea" value="222">Guinea</option>
                                        <option label="Guinea-bissau" value="223">Guinea-bissau</option>
                                        <option label="Guyana" value="224">Guyana</option>
                                        <option label="Haiti" value="225">Haiti</option>
                                        <option label="Heard Island And Mcdonald Islands" value="226">Heard Island And Mcdonald Islands</option>
                                        <option label="Holy See (vatican City State)" value="227">Holy See (vatican City State)</option>
                                        <option label="Honduras" value="52">Honduras</option>
                                        <option label="Hong Kong" value="53">Hong Kong</option>
                                        <option label="Hungary" value="54">Hungary</option>
                                        <option label="Iceland" value="55">Iceland</option>
                                        <option label="India" value="56">India</option>
                                        <option label="Indonesia" value="57">Indonesia</option>
                                        <option label="Iran" value="58">Iran</option>
                                        <option label="Iraq" value="59">Iraq</option>
                                        <option label="Ireland" value="60">Ireland</option>
                                        <option label="Isle Of Man" value="228">Isle Of Man</option>
                                        <option label="Israel" value="61">Israel</option>
                                        <option label="Italy" value="62">Italy</option>
                                        <option label="Jamaica" value="63">Jamaica</option>
                                        <option label="Japan" value="64">Japan</option>
                                        <option label="Jersey" value="229">Jersey</option>
                                        <option label="Jordan" value="65">Jordan</option>
                                        <option label="Kazakhstan" value="230">Kazakhstan</option>
                                        <option label="Kenya" value="231">Kenya</option>
                                        <option label="Kiribati" value="232">Kiribati</option>
                                        <option label="Korea, Democratic People's Republic Of" value="233">Korea, Democratic People's Republic Of</option>
                                        <option label="Kuwait" value="66">Kuwait</option>
                                        <option label="Kyrgyzstan" value="234">Kyrgyzstan</option>
                                        <option label="Lao" value="67">Lao</option>
                                        <option label="Latvia" value="68">Latvia</option>
                                        <option label="Lebanon" value="69">Lebanon</option>
                                        <option label="Lesotho" value="70">Lesotho</option>
                                        <option label="Liberia" value="71">Liberia</option>
                                        <option label="Libyan Arab Jamahiriya" value="235">Libyan Arab Jamahiriya</option>
                                        <option label="Liechtenstein" value="72">Liechtenstein</option>
                                        <option label="Lithuania" value="73">Lithuania</option>
                                        <option label="Luxembourg" value="74">Luxembourg</option>
                                        <option label="Macau" value="75">Macau</option>
                                        <option label="Macedonia (fyr)" value="76">Macedonia (fyr)</option>
                                        <option label="Madagascar" value="77">Madagascar</option>
                                        <option label="Malawi" value="78">Malawi</option>
                                        <option label="Malaysia" value="79">Malaysia</option>
                                        <option label="Maldives" value="80">Maldives</option>
                                        <option label="Mali" value="81">Mali</option>
                                        <option label="Malta" value="82">Malta</option>
                                        <option label="Marshall Islands" value="83">Marshall Islands</option>
                                        <option label="Martinique" value="84">Martinique</option>
                                        <option label="Mauritania" value="85">Mauritania</option>
                                        <option label="Mauritius" value="86">Mauritius</option>
                                        <option label="Mayotte" value="87">Mayotte</option>
                                        <option label="Mexico" value="88">Mexico</option>
                                        <option label="Micronesia" value="89">Micronesia</option>
                                        <option label="Moldova" value="90">Moldova</option>
                                        <option label="Monaco" value="91">Monaco</option>
                                        <option label="Mongolia" value="92">Mongolia</option>
                                        <option label="Montserrat" value="93">Montserrat</option>
                                        <option label="Morocco" value="94">Morocco</option>
                                        <option label="Mozambique" value="95">Mozambique</option>
                                        <option label="Myanmar" value="96">Myanmar</option>
                                        <option label="Namibia" value="97">Namibia</option>
                                        <option label="Nauru" value="98">Nauru</option>
                                        <option label="Nepal" value="99">Nepal</option>
                                        <option label="Netherlands" value="100">Netherlands</option>
                                        <option label="Netherlands Antilles" value="101">Netherlands Antilles</option>
                                        <option label="New Caledonia" value="102">New Caledonia</option>
                                        <option label="New Zealand" value="103">New Zealand</option>
                                        <option label="Nicaragua" value="104">Nicaragua</option>
                                        <option label="Niger" value="105">Niger</option>
                                        <option label="Nigeria" value="106">Nigeria</option>
                                        <option label="Niue" value="107">Niue</option>
                                        <option label="Norfolk Island" value="108">Norfolk Island</option>
                                        <option label="North Korea" value="109">North Korea</option>
                                        <option label="Norway" value="110">Norway</option>
                                        <option label="Oman" value="111">Oman</option>
                                        <option label="Pakistan" value="112">Pakistan</option>
                                        <option label="Palau" value="113">Palau</option>
                                        <option label="Palestinian Territory, Occupied" value="236">Palestinian Territory, Occupied</option>
                                        <option label="Panama" value="114">Panama</option>
                                        <option label="Papua New Guinea" value="237">Papua New Guinea</option>
                                        <option label="Paraguay" value="115">Paraguay</option>
                                        <option label="Peru" value="116">Peru</option>
                                        <option label="Philippines" value="117">Philippines</option>
                                        <option label="Pitcairn" value="118">Pitcairn</option>
                                        <option label="Poland" value="119">Poland</option>
                                        <option label="Portugal" value="120">Portugal</option>
                                        <option label="Puerto Rico" value="121">Puerto Rico</option>
                                        <option label="Qatar" value="122">Qatar</option>
                                        <option label="Reunion" value="123">Reunion</option>
                                        <option label="Romania" value="124">Romania</option>
                                        <option label="Russian Federation" value="125">Russian Federation</option>
                                        <option label="Rwanda" value="126">Rwanda</option>
                                        <option label="Saint Helena" value="127">Saint Helena</option>
                                        <option label="Saint Kitts And Nevis" value="238">Saint Kitts And Nevis</option>
                                        <option label="Saint Lucia" value="128">Saint Lucia</option>
                                        <option label="Saint Pierre And Miquelon" value="239">Saint Pierre And Miquelon</option>
                                        <option label="Saint Vincent And The Grenadines" value="240">Saint Vincent And The Grenadines</option>
                                        <option label="Samoa" value="129">Samoa</option>
                                        <option label="San Marino" value="130">San Marino</option>
                                        <option label="Sao Tome And Principe" value="241">Sao Tome And Principe</option>
                                        <option label="Saudi Arabia" value="131">Saudi Arabia</option>
                                        <option label="Senegal" value="132">Senegal</option>
                                        <option label="Serbia And Montenegro" value="242">Serbia And Montenegro</option>
                                        <option label="Seychelles" value="133">Seychelles</option>
                                        <option label="Sierra Leone" value="134">Sierra Leone</option>
                                        <option label="Singapore" value="135">Singapore</option>
                                        <option label="Slovakia" value="136">Slovakia</option>
                                        <option label="Slovenia" value="137">Slovenia</option>
                                        <option label="Solomon Islands" value="138">Solomon Islands</option>
                                        <option label="Somalia" value="139">Somalia</option>
                                        <option label="South Africa" value="140">South Africa</option>
                                        <option label="South Georgia" value="141">South Georgia</option>
                                        <option label="South Korea" value="142">South Korea</option>
                                        <option label="Spain" value="143">Spain</option>
                                        <option label="Sri Lanka" value="144">Sri Lanka</option>
                                        <option label="Sudan" value="145">Sudan</option>
                                        <option label="Suriname" value="146">Suriname</option>
                                        <option label="Svalbard And Jan Mayen" value="243">Svalbard And Jan Mayen</option>
                                        <option label="Swaziland" value="147">Swaziland</option>
                                        <option label="Sweden" value="148">Sweden</option>
                                        <option label="Switzerland" value="149">Switzerland</option>
                                        <option label="Syria" value="150">Syria</option>
                                        <option label="Taiwan" value="151">Taiwan</option>
                                        <option label="Tajikistan" value="152">Tajikistan</option>
                                        <option label="Tanzania" value="153">Tanzania</option>
                                        <option label="Thailand" value="154">Thailand</option>
                                        <option label="Timor-leste" value="244">Timor-leste</option>
                                        <option label="Togo" value="155">Togo</option>
                                        <option label="Tokelau" value="156">Tokelau</option>
                                        <option label="Tonga" value="157">Tonga</option>
                                        <option label="Trinidad And Tobago" value="158">Trinidad And Tobago</option>
                                        <option label="Tunisia" value="159">Tunisia</option>
                                        <option label="Turkey" value="160">Turkey</option>
                                        <option label="Turkmenistan" value="161">Turkmenistan</option>
                                        <option label="Turks And Caicos" value="162">Turks And Caicos</option>
                                        <option label="Tuvalu" value="163">Tuvalu</option>
                                        <option label="Uganda" value="164">Uganda</option>
                                        <option label="Ukraine" value="165">Ukraine</option>
                                        <option label="United Arab Emirates" value="166">United Arab Emirates</option>
                                        <option label="United Kingdom" value="167">United Kingdom</option>
                                        <option label="United States" value="168">United States</option>
                                        <option label="United States Minor Outlying Islands" value="245">United States Minor Outlying Islands</option>
                                        <option label="Uruguay" value="169">Uruguay</option>
                                        <option label="Uzbekistan" value="170">Uzbekistan</option>
                                        <option label="Vanuatu" value="171">Vanuatu</option>
                                        <option label="Vatican City State See Holy See" value="246">Vatican City State See Holy See</option>
                                        <option label="Venezuela" value="172">Venezuela</option>
                                        <option label="Viet Nam" value="173">Viet Nam</option>
                                        <option label="Virgin Islands (brit)" value="174">Virgin Islands (brit)</option>
                                        <option label="Virgin Islands (u.s.)" value="175">Virgin Islands (u.s.)</option>
                                        <option label="Wallis And Futuna" value="247">Wallis And Futuna</option>
                                        <option label="Western Sahara" value="176">Western Sahara</option>
                                        <option label="Yemen" value="177">Yemen</option>
                                        <option label="Yugoslavia" value="178">Yugoslavia</option>
                                        <option label="Zaire" value="179">Zaire</option>
                                        <option label="Zambia" value="180">Zambia</option>
                                        <option label="Zimbabwe" value="181">Zimbabwe</option>

                                    </select>
                                    
                                </div>
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                        <div class="field-label">City <!--<sup>*  </sup>-->   <span style="font-size:11px; color:#FF0000" id=""></span>
                        </div>
                        <input type="text" name="cityName" id="cityName" value="">           
                                
                    </div>                
                    <div class="clearfix clear" style="clear:both"></div>
        
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                            <div class="field-label">Contact No  <!--<sup>*  </sup>--><span style="font-size:11px; color:#FF0000" id=""></span>
                            </div>
                            <input type="text" name="contactNo" id="contactNo" value="">
                            
                    </div>
                                            
                                            
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                        <div class="field-label">Address <!--<sup>*  </sup>-->   <span style="font-size:11px; color:#FF0000" id=""></span></div>
                    <input type="text" name="address" id="address" value="">
                                                 
                    </div>
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                        <div class="field-label">Email Id  <span style="font-size:11px; color:#FF0000" id=""></span></div>
                        <input type="text" name="emailId" id="emailId" value="">
                        
                    </div>
                    <div class="clearfix clear" style="clear:both"></div>
                                          
                  <div class="form-group col-md-4 col-sm-4 col-xs-12">
                         <div class="field-label">Website <sup>*  </sup>   <span style="font-size:11px; color:#FF0000" id="websiteId"></span></div>
                            <input type="text" name="website" id="website" value="">
                            
                    </div>
            </div>
            <hr>
            <div class="row clearfix">
                    
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                      <div class="field-label">Contact Person  <sup>*  </sup><span style="font-size:11px; color:#FF0000" id="contactPersonId"></span></div>
                        <input type="text" name="contactPerson" id="contactPerson" value="">
                    </div>
                     <div class="form-group col-md-4 col-sm-4 col-xs-12">
                        <div class="field-label">Designation <sup>*  </sup>   <span style="font-size:11px; color:#FF0000" id="designationId"></span></div>
                       <input type="text" name="designation" id="designation" value="">
                                                 
                    </div>                
                                             
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                        <div class="field-label">Contact Pesrson Email Id  <sup>*  </sup><span style="font-size:11px; color:#FF0000" id="per_emailidId"></span></div>
                        <input type="text" name="per_emailid" id="per_emailid" value="">
                        
                    </div>                
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                         <div class="field-label">Contact No./Ext    <span style="font-size:11px; color:#FF0000" id=""></span></div>
                        <input type="text" name="contact_no_ext" id="contact_no_ext" value="">
                        
                    </div>
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                        <div class="field-label">Mobile No. <sup>*  </sup>   <span style="font-size:11px; color:#FF0000" id="mobile_noId"></span></div>
                        <input type="text" name="mobile_no" id="mobile_no" value="">
                        
                    </div>
                    <div class="form-group col-md-4 col-sm-4 col-xs-12">
                        <div class="field-label">Upload Document <!--<sup>*  </sup>-->   <span style="font-size:11px; color:#FF0000" id=""></span></div>
                        <input type="file" name="upload_document" id="upload_document" value="">
                        
                    </div>
                    <div class="clearfix clear" style="clear:both"></div>
                    <div class="form-group col-md-12 col-sm-12 col-xs-12">
                        <div class="field-label">Description <!--<sup>*  </sup>-->   <span style="font-size:11px; color:#FF0000" id=""></span></div>
                        <textarea name="comment" rows="5" id="comment"></textarea>
                        
                    </div>
            </div>
            <hr>    
            <div class="clearfix">

                <table class="demand-table mt-4" style="width:100%;">
                    <tbody><tr>
                <td style="width:25px">S.No</td>
                <td style="width:50px">Industry</td>
                <td style="width:50px">Category</td>
                <td style="width:50px">Position</td>
                <td style="width:50px">Vacancy</td>
                <td style="width:50px">Qualification</td>
                <td style="width:50px">Experience</td>
                <td style="width:50px">Salary Offer</td>
                <td style="width:50px">Benefits </td>
                </tr>
                                                                                                                    <tr>
                <td style="width:25px">1</td>
                <td style="width:50px"><input type="text" name="Industry_1" id="Industry_1" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_1" id="Country_1" value=""></td>
                <td style="width:50px"><input type="text" name="Position_1" id="Position_1" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_1" id="Vacancy_1" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_1" id="Qualification_1" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_1" id="Experience_1" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_1" id="SalaryOffer_1" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_1" id="Benefits_1" value=""></td>
                </tr>
                    <tr>
                <td style="width:25px">2</td>
                <td style="width:50px"><input type="text" name="Industry_2" id="Industry_2" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_2" id="Country_2" value=""></td>
                <td style="width:50px"><input type="text" name="Position_2" id="Position_2" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_2" id="Vacancy_2" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_2" id="Qualification_2" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_2" id="Experience_2" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_2" id="SalaryOffer_2" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_2" id="Benefits_2" value=""></td>
                </tr>
                    <tr>
                <td style="width:25px">3</td>
                <td style="width:50px"><input type="text" name="Industry_3" id="Industry_3" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_3" id="Country_3" value=""></td>
                <td style="width:50px"><input type="text" name="Position_3" id="Position_3" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_3" id="Vacancy_3" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_3" id="Qualification_3" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_3" id="Experience_3" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_3" id="SalaryOffer_3" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_3" id="Benefits_3" value=""></td>
                </tr>
                    <tr>
                <td style="width:50px">4</td>
                <td style="width:50px"><input type="text" name="Industry_4" id="Industry_4" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_4" id="Country_4" value=""></td>
                <td style="width:50px"><input type="text" name="Position_4" id="Position_4" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_4" id="Vacancy_4" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_4" id="Qualification_4" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_4" id="Experience_4" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_4" id="SalaryOffer_4" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_4" id="Benefits_4" value=""></td>
                </tr>
                    <tr>
                <td style="width:25px">5</td>
                <td style="width:50px"><input type="text" name="Industry_5" id="Industry_5" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_5" id="Country_5" value=""></td>
                <td style="width:50px"><input type="text" name="Position_5" id="Position_5" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_5" id="Vacancy_5" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_5" id="Qualification_5" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_5" id="Experience_5" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_5" id="SalaryOffer_5" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_5" id="Benefits_5" value=""></td>
                </tr>
                    <tr>
                <td style="width:25px">6</td>
                <td style="width:50px"><input type="text" name="Industry_6" id="Industry_6" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_6" id="Country_6" value=""></td>
                <td style="width:50px"><input type="text" name="Position_6" id="Position_6" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_6" id="Vacancy_6" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_6" id="Qualification_6" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_6" id="Experience_6" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_6" id="SalaryOffer_6" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_6" id="Benefits_6" value=""></td>
                </tr>
                    <tr>
                <td style="width:25px">7</td>
                <td style="width:50px"><input type="text" name="Industry_7" id="Industry_7" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_7" id="Country_7" value=""></td>
                <td style="width:50px"><input type="text" name="Position_7" id="Position_7" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_7" id="Vacancy_7" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_7" id="Qualification_7" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_7" id="Experience_7" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_7" id="SalaryOffer_7" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_7" id="Benefits_7" value=""></td>
                </tr>
                    <tr>
                <td style="width:25px">8</td>
                <td style="width:50px"><input type="text" name="Industry_8" id="Industry_8" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_8" id="Country_8" value=""></td>
                <td style="width:50px"><input type="text" name="Position_8" id="Position_8" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_8" id="Vacancy_8" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_8" id="Qualification_8" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_8" id="Experience_8" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_8" id="SalaryOffer_8" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_8" id="Benefits_8" value=""></td>
                </tr>
                <tr>
                <td style="width:25px">9</td>
                <td style="width:50px"><input type="text" name="Industry_9" id="Industry_9" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_9" id="Country_9" value=""></td>
                <td style="width:50px"><input type="text" name="Position_9" id="Position_9" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_9" id="Vacancy_9" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_9" id="Qualification_9" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_9" id="Experience_9" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_9" id="SalaryOffer_9" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_9" id="Benefits_9" value=""></td>
                </tr>
                <tr>
                <td style="width:25px">10</td>
                <td style="width:50px"><input type="text" name="Industry_10" id="Industry_10" value=""></td>
                <td style="width:50px"> <input type="text" name="Country_10" id="Country_10" value=""></td>
                <td style="width:50px"><input type="text" name="Position_10" id="Position_10" value=""></td>
                <td style="width:50px"><input type="text" name="Vacancy_10" id="Vacancy_10" value=""></td>
                <td style="width:50px"><input type="text" name="Qualification_10" id="Qualification_10" value=""></td>
                <td style="width:50px"><input type="text" name="Experience_10" id="Experience_10" value=""></td>
                <td style="width:50px"><input type="text" name="SalaryOffer_10" id="SalaryOffer_10" value=""></td>
                <td style="width:50px"><input type="text" name="Benefits_10" id="Benefits_10" value=""></td>
                </tr>
                    
                </tbody></table>  
            </div>
                    <div class="row clearfix mt-4">
                        <div class="form-group col-md-2 col-sm-2 col-xs-12">
                            <div class="field-label">Security Code <!--<sup>*  </sup>-->   <span style="font-size:11px; color:#FF0000" id="scodeId"></span></div>
                                <input id="scode" type="text" name="scode" class="form-control" maxlength="15">
                                                    
                        </div>
                        <div class="form-group col-md-2 col-sm-2 col-xs-12">
                            <div class="field-label">&nbsp; </div>
                                <img src="newcaptcha.php" class="imgcaptcha" style="height: 34px;border: 1px solid #eee;">
                        </div>
                    </div>                   
                
                </div>
            </div>                             
        
            <div class="form-btn-text "> 
                <input type="submit" name="smt2" id="smt2" value="Submit" class="form-sub-btn">
                <input type="reset" name="rst" id="rst" value="Reset" class="form-sub-btn" style="background:#6b6b96;">
            </div>

            </div>
             </div>
         </form></div>
      </div>
	</div>
  </div>  
 </div>
