import { Component, OnInit } from '@angular/core';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.css']
})
export class RegistrationDialogComponent implements OnInit {

  constructor(public dialog: MatDialog, public registerDialogRef: MatDialogRef<RegistrationDialogComponent>) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.registerDialogRef.close();
  }
  openLoginDialog(): void {
    this.registerDialogRef.close();
    const loginDialogRef = this.dialog.open(LoginDialogComponent, {
      width: '421px',
      data: {}
    });

    loginDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }

}
