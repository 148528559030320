import { Component, OnInit } from '@angular/core';
import { RegistrationDialogComponent } from '../registration-dialog/registration-dialog.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  constructor(public dialog: MatDialog, public loginDialogRef: MatDialogRef<LoginDialogComponent>) { }

  ngOnInit(): void {
  }
  openRegisterDialog(): void {
    this.loginDialogRef.close();
    const registerDialogRef = this.dialog.open(RegistrationDialogComponent, {
      width: '421px',
      data: {}
    });

    registerDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onNoClick(): void {
    this.loginDialogRef.close();
  }


}
