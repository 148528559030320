import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { homeRoutes } from './../routes/layout-routing.module';
import { MaterialModule } from '../modules/material.module';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { IndustryComponent } from './industry/industry.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MethodologyComponent } from './methodology/methodology.component';
import { ProfileComponent } from './profile/profile.component';
import { WhyusComponent } from './whyus/whyus.component';
import { QualityPolicyComponent } from './quality-policy/quality-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { VisaServiceComponent } from './visa-service/visa-service.component';
import { RecruitmentComponent } from './recruitment/recruitment.component';
import { EmigrationClearanceComponent } from './emigration-clearance/emigration-clearance.component';
import { DocumentAttestationComponent } from './document-attestation/document-attestation.component';
import { FlightReservationComponent } from './flight-reservation/flight-reservation.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from './registration-dialog/registration-dialog.component';
import { EmployersEnquiryComponent } from './employers-enquiry/employers-enquiry.component';
import { CandidatesEnquiryComponent } from './candidates-enquiry/candidates-enquiry.component';
import { JobsComponent } from './jobs/jobs.component';
import { PostResumeComponent } from './post-resume/post-resume.component';
import { TrackStatusComponent } from './track-status/track-status.component';
import { NgwWowModule } from 'ngx-wow';






@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
	  HomeComponent,
    IndustryComponent,
    MethodologyComponent,
    ProfileComponent,
    WhyusComponent,
    QualityPolicyComponent,
    ContactUsComponent,
    VisaServiceComponent,
    RecruitmentComponent,
    EmigrationClearanceComponent,
    DocumentAttestationComponent,
    FlightReservationComponent,
    LoginDialogComponent,
    RegistrationDialogComponent,
    EmployersEnquiryComponent,
    CandidatesEnquiryComponent,
    JobsComponent,
    PostResumeComponent,
    TrackStatusComponent
  
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    MaterialModule,
    NgwWowModule,
    RouterModule.forChild(homeRoutes)
  ]
})
export class LayoutModule { }
