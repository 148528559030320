<div class="emigration-page">   
    <div class="page-banner" id="header-eliment">
      <div class="container">
            <div class="banner-contents">             
              <h2>Emigration Clearance</h2>
              <div class="d-flex align-items-center">
                <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Emigration Clearance</p>
            </div>
            </div>  
        </div>       
    </div>
     <div class="profile-area">
         <div class="container">
          <h4 class="my-4 profile-head"> Emigration Clearance</h4>
          <p>Rollic India offers a wide range of immigration counselling and representation to immigrants and their families. We help clients understand the immigration law, learn their rights under the law, and to complete the immigration process effectively. Our staff includes fully-accredited immigration professionals, attorneys, translators, and case managers.</p>
          <p>We offer low-cost support to obtain immigrant visas, naturalization, adjustment of status to legal permanent residency, asylum, special immigrant status, temporary visas, non-immigrant visas, deferred action applications, consular processing, renewals, work permit renewals, affidavits of support, etc.</p>
          <h5 class="mt-4"><strong>Work Permits</strong></h5>
          <p>Foreign workers are required to have the proper visas and work permits in Saudi Arabia, as established by immigration laws. Work permits must be secured for employees, and sponsored by a locally licensed and incorporated entity, which can be a problem for companies just entering the Saudi Arabian market. If you have yet to complete the incorporation process you can use an outsourced management company or GEO Employer of Record to sponsor the employee for the necessary permits.</p>
          <h5 class="mt-4"><strong>Process of Emigration Clearance</strong></h5>
          <p>A work visa is sponsored by an employer. This can be a Saudi company, a Saudi citizen or a foriegn entity appropriately licensed to do business in Saudi Arabia.</p>
          <ul class="mb-3 list-rules">
            <li>The employer must first register with the Ministry of Interior who will open an “immigration file”. This will contain details of all expatriate employees with that employer. The sponsoring employer must be registered in Saudi Arabia and hold a valid commercial license. The employer may be asked to submit a variety of documents listed below. The employer will likely be asked to show a commitment to “Saudization” which is to prove that there are no Saudi citizens available to fill the position, and that the employer is taking steps to employ Saudi citizens where possible.</li>
            <li>The employer then lodges a work visa application with the Ministry of Labor. This can take 1-3 months.</li>
            <li>The Ministry of Labor will advise the Ministry of Foreign Affairs when the application is approved. The Ministry of Foreign affairs will issue a Visa Authorisation Number. This takes 1-2 weeks.</li>
            <li>The actual Work Visa is issued by the relevant Saudi embassy under the instruction of the Minisry of Foreign Affairs. At this point the employee will have to apply at the revelant Saudi Arabian embassy. The employee must submit an application form and the documents outlined below to the embassy. Processing times vary by embassy but generally 1-3 weeks. After the embassy issues the Work / Employment visa, the individual can travel to Saudi Arabia. The Work / Employment visa will allow a stay of between 14-90 days.</li>
            <li>On arrival an application must be filed with the Ministry of Labor for the Iqama or residency permit. Once approved by Ministry of Labour it will be forwarded to Ministry of Interior to issue the Iqama or residency permit. The Directorate General for Passports, within the Ministry of Interior, will issue the Iqama. The employer will need to obtain the work permit from the Ministry of Labor gor the employee to show at the passport office when applying for the Iqama. The Iqama normally takes 1-3 weeks to be issued. The employee should carry the Iqama with them at all times as it proves they have the legal right to live and work in Saudi Arabia. The employee can be fined if the Iqama is lost and not reported lost, or if it expires before renewal.</li>
            <li>Lastly after the Iqama is issued then an application is lodged with the Ministry of Interior for an Exit/Re-Entry Permit which allows the employee to travel in and out of Saudi Arabia whilst they have a valid Work Permit and Iqama. The length of permit and type will depend on a number of factors including the nationality and profession of the employee. It takes approximately 1 week to issue.</li>
          </ul>
          <h5 class="my-3">Requirements for the Employee to present at the relevant Saudi Arabian embassy:</h5>
           <ul class="mb-3 list-rules">
             <li>Original valid signed employment contract in Arabic</li>
             <li>Original passport valid for at least the duration of the employment contract and it must have at least two full blank adjacent visa pages. (note if an Israeli visa stamp is found in your passport, the Saudi embassy will likely refuse to grant a visa application)</li>
             <li>Medical certificate stating the applicant is free of contagious diseases. This should be issued by a clinic approved by the Saudi Embassy and be issued within 3 months of the application being lodged.</li>
             <li>Completed application form (BLOCK LETTERS and black ink)</li>
             <li>In order to transfer from one employer to another, a No-Objection Certificate (NOC) from the previous employer is required.</li>
             <li>If previously worked in Saudi Arabia a copy of the relevant exit visa and a reference from the previous employer may be required</li>
             <li>Copy of relevant academic qualifications (notarised by the educational institution and the Saudi embassy)</li>
             <li>Color passport photos. The number required varies by embassy. (it is advised to dress conservatively and avoid wearing religious symbols)</li>
             <li>Original support letter from the sponsoring employer, certified by the Saudi Arabian Chamber of Commerce and the Ministry of Foreign Affairs</li>
             <li>Copy of the invitation letter issued by Saudi Ministry of Foreign Affairs (this will reference the employer name, and the number of the employment visa)</li>
             <li>Police clearance dated within 6 months of the application</li>
             <li>Personality</li>
           </ul>
           <h5 class="my-3">Requirements of the Employer to submit to Ministry of Interior:</h5>
           <ul class="mb-3 list-rules">
                 <li>Company Registration (CR)</li>
                 <li>Company Stamp</li>
                 <li>Zakat Certificate</li>
                 <li>GOSI Certificate</li>
                 <li>Chamber of Commerce Certificate</li>
                 <li>KROKI Certificate</li>
                 <li>NITAQAT statement</li>
            </ul>
            <h5 class="my-3">Use the Shield GEO Employer of record solution</h5>
            <p>Once you get in touch with us, one of our consultants will take all the work off your hand, co-ordinate with our local partners to get all the required permits organised, provide the processing time, costs, document-checklist and keep you informed through the process. Contact us to know more.</p>
        </div>
     </div>
 </div>