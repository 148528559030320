<h1 mat-dialog-title>Register<span class="mat-dialog-subtitle">Welcome back!</span></h1>
<div mat-dialog-content>
  <form class="user-login-form">
	<div class="form-group">
		<label for="exampleInputEmail1">Email</label>
		<input type="email" class="form-control" id="exampleInputEmail1" placeholder="userdemo@chero.com" aria-describedby="emailHelp">
	</div>
	<div class="form-group">
		<label for="exampleInputPassword1">Password</label>
		<input type="password" class="form-control" placeholder="***********" id="exampleInputPassword1">
		<span class="viewPass">
			<mat-icon>visibility_off</mat-icon>
		</span>
	</div>
	<div class="form-group text-center">
		<small class="form-text text-muted">Forgot password?</small>
	</div>
	<button type="submit" class="btn btn-primary mb-2">Register</button>
	<p>Don’t have a account? <a [routerLink]=""  (click)="openLoginDialog()">Login here</a></p>
  </form>
</div>