import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-attestation',
  templateUrl: './document-attestation.component.html',
  styleUrls: ['./document-attestation.component.css']
})
export class DocumentAttestationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('top-hide').style.display="none";
  }

}
