<div class="methodolgy-page">   
   <div class="page-banner" id="header-eliment">
    <div class="container">
           <div class="banner-contents">             
             <h2>Methodology</h2>
             <div class="d-flex align-items-center">
              <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Methodology</p>
           </div>
           </div>        
      </div> 
    </div>
    <div class="profile-area">
        <div class="container">
         <h4 class="my-4 profile-head"> Methodology</h4>
         <p>
		  <img class="section-img" src="../../../assets/img/methodology1.jpg"/>
		 The recruitment operations at Canway Immigration Consultants Ltd. is a highly defined and exclusive process, instrumented by priority, precision, pragmatism and expediency. The company adheres to a highly competitive and standardized methodology for the process of selecting ‘the best’ from amongst ‘the best’. This infallible methodology is reflected in the confidence and trust of its vast clientele.
          Canway Immigration Consultants Ltd. has a panel of consultants in each field working with on full-time basis. Add to this, is another set of specialized consultants who work on a project-to-project basis.. </p>
         <p>These highly specialized consultants are sourced through its extensive network. Furthermore, the company can send relevant and applicable resumes from its vast databank both in manual as well as computerized formats. Taking off from here, the selection procedure is as follows:-</p>
         <h5 class="mt-4"><strong>Selection Procedure</strong></h5>
         <ul class="mb-3 list-rules">
           <li>Receive the attested Power of Attorney, Demand Letter and Specimen Copy of Employment Contract.</li>
           <li>Submit the power of attorney and demand-letter to the consulate and collect the name of the approved panel doctor for the country.</li>
           <li>Make an artwork of the advertisement.</li>
           <li>Get approval of the artwork from the client (optional).</li>
           <li>Release the advertisement in relevant newspapers having the best readership among the target-appropriate candidates.</li>
           <li>Receive applications, scrutinize and shortlist candidates.</li>
           <li>Send the short-listed candidates’ CV to the client and get a list of candidates selected by them.</li>
           <li>Organize final interviews, covering all aspects.</li>
           <li>Send candidates for trade test (if necessary).</li>
           <li>Get new list of selected candidates.</li>
           <li>Send candidates for medical test.</li>
           <li>R•	Receive final confirmation from the client.</li>
         </ul>
         <h5 class="my-3"><strong>Candidate Scrutiny</strong></h5>
         <p>Candidate scrutiny forms the most important part of the operations. Canway Immigration Consultants Ltd. ensures that this process is foolproof, and highly prudent.
          The company has specialized consultants for:</p>

          <ul class="mb-3 list-rules">
            <li>Personality evaluation.</li>
            <li>Language skills (Fluency).</li>
            <li>Primary tests to evaluate the social skill of the candidate.</li>
          </ul>
          <p>These primary evaluation processes itself eliminates a whole lot of candidates. The next phase of the selection process involves tests on vacancy skills. The candidate is evaluated on the technical issues of the vacancy. These are:-</p>
            <ul class="mb-3 list-rules">
              <li>Preliminary written tests.</li>
              <li>Viva voce.</li>
              <li>Specialized Tests for all trades and industry.</li>
            </ul>
            <p>Apart from these in-house testing facilities, Canway Immigration Consultants Ltd. has a contract with the local government recognized technical institutes, which allows access to technical facilities.</p>
        </div>
    </div>
</div>