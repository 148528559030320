<div class="document-attestation-page">   
    <div class="page-banner" id="header-eliment">
         <div class="container">
            <div class="banner-contents">             
              <h2>Documens Attestation</h2>
                <div class="d-flex align-items-center">
                    <a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Documens Attestation</p>
                </div>
            </div>   
        </div>      
    </div>
     <div class="profile-area">
        <div class="container">
            <div class="d-flex justify-content-between flex-wrap mt-4">
                    <div class="service-box-top">
                        <h4 class="mb-3 profile-head"> Documens Attestation</h4>
                        <p>Document attestation services are the facilities that be of help to the citizens to reach out to the government authorities to get the legalization of documents done. These agencies actually make the process simpler and are easy to reach when the requirement for authentication is dire. The consultant agency has reaches at the government authorities which gives them the reach to obtaining the certification of documents on urgent notices as well.</p>
                        <p>Document attestation is simply the authentication of documents that is mandatory for various purposes. Attestation in India can be of various types, at times for local use it requires self-attestation while at other times, it is mandated from the highest authority like central government. Attestation of documents shall provide a document certain immunity to be accepted in different parts of the nation or abroad. This type of certificate attestation can be for local use or at times for the national or international cause.</p>
                        <p>Rollic India is placed amongst the best of certificate attestation services in Delhi. We have been performing attestation in Delhi for 6+ years and are well-known to have maintained the standard of our services over the years and keeping our clients content.</p>
                    </div>
                    <div class="registration-form">
                        <div class="form-heading">
                            <h1>document attestation</h1>
                        </div>                
                        <div class="form-body">
                            <ul class="doc-attestation">
                                <a href="#" routerLink="/document-attestation/embassy-attestation"><li>Embassy Attestation</li></a>
                                <a href="#" routerLink="/document-attestation/hrd-attestation"><li>HRD Attestation</li></a>
                                <a href="#" routerLink="/document-attestation/mea-attestation"><li>MEA Attestation</li></a>
                                <a href="#" routerLink="/document-attestation/mea-apostille"><li>MEA Apostille</li></a> 
                                <a href="#" routerLink="/document-attestation/hrd-attestation"><li>SDM attestation</li></a> 
                                <a href="#" routerLink="/document-attestation/home-department"><li>Home Department</li></a> 
                            </ul>
                        </div>
                    </div>
            </div>
            <div class="service-contents-area mt-4">
                <h5 class=""><strong>How to get Certificate Attestation in Delhi?</strong></h5>
                <p>There are very constrained genuine services in Delhi and searching for them can prove to be cumbersome task. Rollic India has been fulfilling its customers’ needs since 2013. For more than six years, we have rendered many individuals and corporate clients. Rollic India is one such approved certificate verification agency in Delhi, where we offer you with professional services of certification of documents. Convenience and fast authentication services is our sRollic Indiaiality, get your documents attested and verified quickly. We are functional in all the steps that include the certificate legalization procedure. We provide following services:-</p>
                <ul class="mb-3 list-rules">
                <li>Embassy Attestation</li>
                <li>MEA Attestation</li>
                <li>Apostille Attestation</li>
                <li>HRD Attestation</li>
                <li>SDM attestation</li>
                <li>Home Department/Mantralaya attestation</li>
                <li>Notary attestation</li>
                </ul>
                <h5 class="mt-4"><strong>Types of Documents Attested for Saudi Arabia:</strong></h5>
                <P>There are mainly 3 types of documents which needs authentication, personal, educational and commercial. We are experienced professionals who are revised with the services of document attestation. We provide verification for all personal documents like birth certificate, marriage certificate, PCC or educational documents such as mark-sheets, degree certificate, school leaving certificate, etc or the commercial documents like memorandum of association, board resolution, certificate of incorporation.</P>
                
                <div class="rgidtration-box-row d-flex justify-content-around flex-wrap mt-5">
                    <div class="registration-box text-center">
                        <div class="img-holder">
                            <img src="../../../assets/img/personal.png" alt="">
                        </div>  
                        <h2>Personal</h2>
                        <!-- <p>Our GST registration services are designed to help you in getting your business GST compliant.</p> -->
                    </div>
                    <div class="registration-box text-center">
                        <div class="img-holder">
                            <img src="../../../assets/img/educational.png" alt="">
                        </div>  
                        <h2>Educational</h2>
                        <!-- <p>Reduce the chance of mismatch significantly through invoice matching with vendors and customers.</p> -->
                    </div>
                    <div class="registration-box text-center">
                        <div class="img-holder">
                            <img src="../../../assets/img/comercial.png" alt="">
                        </div>  
                        <h2>Commercial</h2>
                        <!-- <p>Our GST compliance services are designed to getting GST returns filed from the comfort of your office</p> -->
                    </div>
                </div> 

                <h5 class="mt-5"><strong>What is the process for Certificate Attestation in Delhi?</strong></h5>
                <p>The process for certificate authentication from MEA or Embassy/consulate is indirect process. You need to go through several levels of legalization steps, the process consists of verification from local officials, state government officials to the central government. The process depends on the type of the document. If you select professional services, the process starts from document submission in Delhi. After which actual certificate legalization starts, which are as follows:</p>
                <div class="tree-img text-center mt-4">
                    <img src="../../../assets/img/tree.svg" alt="">
                </div> 

            <h5 class="mt-5"><strong>Why choose US?</strong></h5>
            <p class="mb-2">Rollic India has been working for over 6+ years now and obtained itself the name of best services in India. We have developed this confidence and credit all because of the backing and professionalism that we have gained over the years. Let us show you how we can aid you with the best utilities:</p>
            <div class="why-choose-row d-flex justify-content-between flex-wrap my-5">
                <div class="col-lg-4 col-md-4 col-12">
                    <div class="choose-box text-center wow slideInLeft">
                        <div class="img-choose">
                            <img src="../../../assets/img/choose.png" alt="">
                        </div>  
                        <h2>Fast & Reliable Service</h2>
                        <p>The facilities are legitimate & verified, we render fast legalization for your documents. We are commissioned and are in confidence of our clients.</p>
                    </div>                      
                </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="choose-box text-center wow pulse">
                            <div class="img-choose">
                                <img src="../../../assets/img/choose.png" alt="">
                            </div>  
                            <h2>Quality Service</h2>
                            <p>We, at our agency, have services of the chief quality with authenticity. Accuracy and expertise in work are guaranteed.</p>
                        </div>                      
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                            <div class="choose-box text-center wow slideInRight">
                            <div class="img-choose">
                                <img src="../../../assets/img/choose.png" alt="">
                            </div>  
                            <h2>Customer Convenience</h2>
                            <p>Clients are of priority to us and we are technology sufficient to make our facilities of convenience to our customers. We maintain transparency of the progress with our clients and there are no hidden costs.</p>
                        </div>                      
                    </div>
                </div>
            
                </div>


        </div>
     </div>
 </div>