import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { homeRoutes } from './routes/layout-routing.module';
import { LayoutModule } from './layout/layout.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    AppComponent,   
   
  ],
  imports: [
    BrowserModule,
	LayoutModule,
    RouterModule.forRoot(homeRoutes),
    BrowserAnimationsModule,
	
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
