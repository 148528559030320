import { Component, OnInit, HostListener } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { RegistrationDialogComponent } from '../registration-dialog/registration-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    let headerH = document.getElementById('header-eliment');
   
    console.log("valueis :",headerH);
    if(headerH === null){
    
      document.getElementById('tophead').classList.add('sticky-nav');
    } else {
      if (document.body.scrollTop > headerH.clientHeight ||     
        document.documentElement.scrollTop > headerH.clientHeight ) {
          document.getElementById('tophead').classList.add('sticky-nav');
		
        } else {
          document.getElementById('tophead').classList.remove('sticky-nav');
		 
        }
    }
   
  }

   // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //    if (window.pageYOffset > 250) {
  //      let element = document.getElementById('tophead');
  //      element.classList.add('sticky-nav');
  //    } else {
  //     let element = document.getElementById('tophead');
  //       element.classList.remove('sticky-nav'); 
  //    }
  // }

  loginDialog(): void {
    //debugger;
    const dialogRef = this.dialog.open(LoginDialogComponent, {
    width: '421px',
    data: {}
    //data: {name: dialogName}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}

registerDialog(): void {
  //debugger;
  const loginDialogRef = this.dialog.open(RegistrationDialogComponent, {
    width: '421px',
    data: {}
  });

  loginDialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    //this.animal = result;
  });
}






}
