import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employers-enquiry',
  templateUrl: './employers-enquiry.component.html',
  styleUrls: ['./employers-enquiry.component.css']
})
export class EmployersEnquiryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
