
<div class="track-status-page">
	<div class="page-banner" id="header-eliment">
		<div class="container">
           <div class="banner-contents">             
			 <h2>Track Application Status</h2>
			 <div class="d-flex align-items-center">
				<a href="#" routerLink="/home" routerLinkActive="active"> <i class="fa fa-home">&nbsp;&nbsp;</i>Home</a> / <p> Track Application Status</p>
             </div>
		   </div>  
		</div>       
    </div>
	<div class="track-status-area">
        <div class="container">
                <div class="section-heading track-main-heading">
                    <h2> Track Your Application Status </h2>
                </div>
            <div class="shop-form">
                <span style="color:#FF0000; font-size:11px;"></span>
                    <div class="row clearfix">
                        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                            <div class="row clearfix">
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">Please enter Passport No. / Email ID<sup>*  </sup> <span style="font-size:11px; color:#FF0000" id="jobseekerNoId"></span> </div>
                                    <input type="text" name="jobseekerNo" id="jobseekerNo" value="" placeholder="Enter Passport No. / Email ID">
                                </div>
                                 <div class="form-group col-md-2 col-sm-2 col-xs-12">
                                    <div class="field-label">Security Code <sup>*</sup>  <span style="font-size:11px; color:#FF0000" id="scodeId"></span></div>
                                      <input id="scode" type="text" name="scode" class="form-control" maxlength="15">
                                </div>
                                 <div class="form-group col-md-2 col-sm-2 col-xs-12">
                                  <div class="field-label">&nbsp; </div>
                                    <img src="newcaptcha.php" class="imgcaptcha" style="height: 34px;border: 1px solid #eee;">
                                </div>
                           </div>
                        </div>
                    </div>          
                <div class=" form-btn-text "> 
                    <input type="submit" name="smt" id="smt" value="Check Status" class="form-sub-btn">
                </div>
            </div>
           
        </div>  
    </div>
</div>