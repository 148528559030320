import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flight-reservation',
  templateUrl: './flight-reservation.component.html',
  styleUrls: ['./flight-reservation.component.css']
})
export class FlightReservationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('top-hide').style.display="none";
  }

}
